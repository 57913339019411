import * as React from 'react';
import { Paper, Typography, List, ListItem, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/Warning';
import { PasswordRule } from '../models/PasswordRule';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1, 1),
        },
        icon: {
            marginRight: theme.spacing(1),
        },
    })
);

export interface PasswordRulesProps {
    password: string;
    verifyPassword: string | undefined;
    passwordValid: (s: boolean) => void;
}

const PasswordRules: React.FunctionComponent<PasswordRulesProps> = (props) => {
    const styles = useStyles();
    const { t } = useTranslation();

    const password = props.password ? props.password : '';
    const verifyPassword = props.verifyPassword ? props.verifyPassword : '';

    const rulesList = new Array<PasswordRule>();
    rulesList.push(new PasswordRule(password.length >= 8, t('password_rules.length', { length: 8 })));
    rulesList.push(new PasswordRule(/.*[a-z].*/.test(password), t('password_rules.requires_lowercase_letters')));
    rulesList.push(new PasswordRule(/.*[A-Z].*/.test(password), t('password_rules.requires_capital_letters')));
    rulesList.push(new PasswordRule(/.*\d.*/.test(password), t('password_rules.requires_numbers')));
    rulesList.push(new PasswordRule(password.length > 0 && password === verifyPassword, t('password_rules.requires_equal')));

    props.passwordValid(rulesList.find((x) => x.success === false) === undefined);

    return (
        <Paper className={styles.root}>
            <Typography variant="subtitle2">
                <b>{t('password_rules.title')}</b>
            </Typography>
            <List dense={true}>
                {rulesList.map((rule) => (
                    <ListItem key={rule.message}>
                        {rule.success ? <DoneIcon className={styles.icon} /> : <WarningIcon className={styles.icon} />}
                        {rule.message}
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
};

export default PasswordRules;
