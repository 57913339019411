export default class ImageModel {
	public id: number;
	public imageHeight: number;
	public imageWidth: number;
	public lotId: number;
	public order: number;
	public path: string;
	public thumbnail: string;
	public thumbnailImageHeight: number;
	public thumbnailImageWidth: number;

	constructor(object: any) {
		this.id = object.id;
		this.path = object.image.path;
		this.imageHeight = object.image.imageHeight;
		this.imageWidth = object.image.imageWidth;
		this.thumbnail = object.thumbnail.path;
		this.thumbnailImageHeight = object.thumbnail.imageHeight;
		this.thumbnailImageWidth = object.thumbnail.imageWidth;
		this.lotId = object.lotId;
		this.order = object.order;
	}
}
