import { fullDateFormat } from 'utilities/dateFormatter';

export default class ArchiveItemViewModel {
    public id: number;
    public name: string;
    public subtitle: string;
    public goToCatalogText: string = 'Detaljer';

    constructor(public object: any) {
        this.name = object.title;
        this.id = object.id;
        const dateString = fullDateFormat(new Date(object.date));
        this.subtitle = dateString;
    }
}
