import * as React from 'react';
import ReactModal from 'react-modal';
import { StoreContext } from 'stores';
import { observer } from 'mobx-react-lite';
import { Typography, Divider, Button, FormControlLabel, Checkbox } from '@mui/material';
import MaxBidToggle from './views/maxBidToggle';
import { truncate } from 'common/utilities/truncate';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export interface MaxBidModalProps {}

const MaxBidModal: React.FunctionComponent<MaxBidModalProps> = observer(() => {
    const { biddingStore } = React.useContext(StoreContext);
    const [receiveMessage, setReceiveMessage] = React.useState(true);

    if (!biddingStore.currentBid) {
        return <div />;
    }

    const bid = biddingStore.currentBid.bid;

    return (
        <ReactModal isOpen={biddingStore.isMaxBidding} style={customStyles}>
            <Typography gutterBottom variant="h5">
                Læg max bud på kat.nr. {truncate(`#${biddingStore.currentBid!.catalogNumber}: ${biddingStore.currentBid!.text}`, 20)}
            </Typography>
            <Divider />
            <br />
            <Typography variant="body1" gutterBottom>
                Du er ved at lægge et max bud på:
            </Typography>

            {/* <div style={{display: "flex", justifyContent: "center" }} > */}
            <MaxBidToggle initialBid={bid} />
            {/* </div> */}

            <br />
            <FormControlLabel control={<Checkbox checked={receiveMessage} onChange={() => setReceiveMessage(!receiveMessage)} />} label="Vil du modtage en besked ved overbud?" />

            <Typography variant="subtitle2">Ved at lægge budet bekræfter du at have læst vores købskonditioner.</Typography>

            <div style={{ paddingTop: '1em' }}>
                <Button
                    style={{ marginRight: '1em' }}
                    size="large"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        biddingStore.endBidding();
                    }}
                >
                    Fortryd
                </Button>
                <Button
                    style={{ float: 'right' }}
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        biddingStore.confirmBid(biddingStore.currentBid!, receiveMessage);
                    }}
                >
                    Læg Bud
                </Button>
            </div>
        </ReactModal>
    );
});

export default MaxBidModal;
