import { Typography } from '@mui/material';
import * as Sentry from '@sentry/browser';
import { get } from 'common/network';
import { action, autorun, makeObservable, observable } from 'mobx';
import CatalogModel from 'models/backend/catalogModel';
import LotBackendModel from 'models/backend/lotBackendModel';
import { ICatalogDto } from 'models/dtos/ICatalogDto';
import SectionModel from 'models/sectionModel';
import React from 'react';
import lotsForSection from '../utilities/lots_for_section';
import { IStore } from './index';
import { Severity, ToastStore } from './ToastStore';

export class Result<T> {
    object: T | undefined;
    errorMessage: React.ReactNode;

    constructor(obj: T | undefined, errorMessage: React.ReactNode) {
        this.object = obj;
        this.errorMessage = errorMessage;
    }
}

export class CatalogStore {
    constructor(private rootStore: IStore, private toastStore: ToastStore) {
        makeObservable(this);
        autorun(() => {
            if (!rootStore.userStore.isAuthenticated) {
                this.catalogs.clear();
            }
        });
    }

    @observable private catalogs = new Map<string, Result<CatalogModel>>();
    private isLoadingCatalog = new Array<string>();

    public catalog(catalogId: string): Result<CatalogModel> | undefined {
        if (!this.catalogs.has(catalogId)) {
            this.fetchCatalog(catalogId);
            return undefined;
        }

        return this.catalogs.get(catalogId);
    }

    public section(catalogId: string, sectionId: string): SectionModel | undefined {
        const catalogModel = this.catalog(catalogId);
        if (!catalogModel) {
            return undefined;
        }

        const sectionResult = lotsForSection(catalogModel.object!, sectionId);
        if (sectionResult.object !== undefined) {
            return new SectionModel(sectionResult.object.section, sectionResult.object.lots);
        } else {
            return undefined;
        }
    }

    public lot(catalogId: string, lotId: string): LotBackendModel | undefined {
        const catalog = this.catalog(catalogId);
        if (!catalog) {
            return undefined;
        }

        const catalogObject = catalog.object;

        if (!catalogObject) {
            console.error(`Failed to find catalog with id: ${catalogId}`);
            return undefined;
        } else {
            const lot = catalogObject.lots.find((x) => x.id === lotId);
            return lot;
        }
    }

    @action private async fetchCatalog(catalogId: string) {
        if (this.isLoadingCatalog.find((x) => x === catalogId) !== undefined) {
            return;
        }

        this.isLoadingCatalog.push(catalogId);

        if (this.catalogs.has(catalogId)) {
            return;
        }

        console.debug('loading catalog: ' + catalogId);

        try {
            const response = await get<ICatalogDto>(undefined, `catalogs/${catalogId}`, false);
            const catalogModel = new CatalogModel(response);

            console.debug('Loaded catalog', catalogModel);
            this.catalogs.set(catalogId, new Result(catalogModel, ''));
        } catch (ex: any) {
            console.debug('Failed to load catalog');
            if (ex.response && ex.response.status === 404) {
                this.catalogs.set(
                    catalogId,
                    new Result<CatalogModel>(
                        undefined,
                        (
                            <Typography variant="body1">
                                Vi kunne desværre ikke finde det efterspurgte katalog
                                <br />
                                Vi har sendt en besked til vores team som vil undersøge problemet.
                            </Typography>
                        )
                    )
                );
            }

            Sentry.captureMessage(`Failed to find catalog with id: '${catalogId}'`, 'error');

            this.errorHandler(ex);
        }

        this.isLoadingCatalog = this.isLoadingCatalog.filter((x) => x !== catalogId);
    }

    private errorHandler(ex: any): string {
        Sentry.captureException(ex);
        if (ex.response) {
            console.error(ex.response.data);
            this.toastStore.showMessage(ex.response.data, Severity.Error);
            return ex.response.data;
        } else {
            if (ex.message) {
                console.error(ex.message);
                this.toastStore.showMessage(ex.message, Severity.Error);
                return ex.message;
            } else {
                console.error(ex);
                this.toastStore.showMessage(ex, Severity.Error);
                return ex;
            }
        }
    }
}
