import LotModel from 'models/backend/lotBackendModel';
import CatalogSectionModel from 'models/backend/catalogSectionBackendModel';

export default class SectionModel {
    public id: string;
    public name: string;
    public fromLot: number;
    public lots: LotModel[];

    constructor(sectionModel: CatalogSectionModel, lots: LotModel[]) {
        this.id = sectionModel.id;
        this.name = sectionModel.title;
        this.fromLot = sectionModel.fromLot;
        this.lots = lots;
    }
}
