import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useAxios from 'axios-hooks';
import ActivityIndicator from 'common/views/activityIndicator';
import DefaultMargin from 'components/DefaultMargin';
import React from 'react';
import Markdown from 'utilities/markdown';

const useStyles = makeStyles((theme: Theme) => ({
    markdown: {
        ...theme.typography.body2,
        a: {
            color: 'red',
        },
    },
}));

interface GenericPageProps {
    markdownPath: string;
}

const GenericPage: React.FunctionComponent<GenericPageProps> = (props) => {
    const classes = useStyles();

    const [{ data, loading }] = useAxios(props.markdownPath);
    if (loading) {
        return <ActivityIndicator />;
    }

    return (
        <DefaultMargin>
            <Markdown className={classes.markdown} key={data.substring(0, 40)}>
                {data}
            </Markdown>
        </DefaultMargin>
    );
};

export default GenericPage;
