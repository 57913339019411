import { Container, Divider, Paper, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import DefaultMargin from 'components/DefaultMargin';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { validateEmail } from 'utilities/validateEmail';
import GetNewPassword from './getNewPassword';
import GetUsername from './getUsername';
import GetVerificationCode from './getVerificationCode';

type ForgotPasswordPageProps = {
    username: string;
};

const ForgotPasswordPage: React.FunctionComponent = () => {
    const [email, setEmail] = React.useState<string>('');
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [verificationCode, setVerificationCode] = React.useState<string | undefined>(undefined);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        const state = location.state as ForgotPasswordPageProps;
        if (state.username) {
            setEmail(state.username);
        }

        return () => {};
    }, [setEmail, location]);

    const hasValidEmail = validateEmail(email);

    return (
        <DefaultMargin>
            <Container maxWidth="xs">
                <Paper style={{ padding: '1em' }}>
                    <Typography gutterBottom variant="h5">
                        {t('forgot_password.title')}
                    </Typography>
                    <Divider style={{ marginBottom: '1em' }} />
                    {!hasValidEmail && <GetUsername initial={email} completed={setEmail} />}
                    {hasValidEmail && !verificationCode && <GetVerificationCode username={email} completed={setVerificationCode} />}

                    {hasValidEmail && verificationCode && (
                        <GetNewPassword
                            completed={async (password) => {
                                try {
                                    await Auth.forgotPasswordSubmit(email, verificationCode, password);
                                    navigate('/logon');
                                } catch (ex: any) {
                                    if (ex.code === 'ExpiredCodeException') {
                                        setVerificationCode(undefined);
                                        setError(t('forgot_password.invalid_code'));
                                        return false;
                                    } else {
                                        console.error(ex);
                                        setError(ex.message);
                                        return false;
                                    }
                                }
                            }}
                        />
                    )}
                    {error && <Typography variant="body1">{error}</Typography>}
                </Paper>
            </Container>
        </DefaultMargin>
    );
};

export default ForgotPasswordPage;
