import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TextInput from 'common/form/textInput';
import React from 'react';
import { validateEmail } from 'utilities/validateEmail';

const useStyles = makeStyles((theme) => ({
    usernameInput: {
        width: '100%',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
}));

interface GetUsernameProps {
    initial: string;
    completed: (email: string) => void;
}

const GetUsername: React.FunctionComponent<GetUsernameProps> = (props) => {
    const styles = useStyles(props);
    const [username, setUsername] = React.useState(props.initial);

    const validEmail = validateEmail(username);

    return (
        <React.Fragment>
            <Typography variant="body1">Indtast email adressen der hører til din konto:</Typography>
            <TextInput
                className={styles.usernameInput}
                fieldName="username"
                label="E-mail"
                object={{ username }}
                onChange={(value) => setUsername(value.currentTarget.value)}
                errorMessage={!validEmail && username.length > 0 ? 'Denne email er ikke gyldig' : undefined}
            />
            <Button color="primary" variant="contained" disabled={!validEmail} onClick={() => props.completed(username)}>
                Gå Videre
            </Button>
        </React.Fragment>
    );
};

export default GetUsername;
