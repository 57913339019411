import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import Title from 'common/views/title';
import DefaultMargin from 'components/DefaultMargin';
import * as React from 'react';

const Jobs: React.FunctionComponent = () => {
    return (
        <DefaultMargin>
            <Title>Ledige stillinger ved Holstebro Auktioner</Title>
            <Typography style={{ marginTop: '1em', marginBottom: '1em' }} variant="body1">
                Der er fart på hos Holstebro Auktioner og derfor søger vi endnu flere talenter til vores familie!
            </Typography>
            <Card sx={{ maxWidth: 345 }}>
                <CardMedia component="img" height="140" image="https://mui.com/static/images/cards/contemplative-reptile.jpg" alt="green iguana" />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Jobtitel
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Vi søger en der er mega god til X Y og Z. Specielt Z i nogen situationer.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small">Søg stillingen</Button>
                </CardActions>
            </Card>
        </DefaultMargin>
    );
};

export default Jobs;
