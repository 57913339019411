/**
 * Generic response type
 */
export class Response<T> {
	data: T | undefined;
	errorMessage: string | undefined;
	loading: boolean;
	constructor(data: T | undefined, errorMessage: string | undefined, loading: boolean) {
		this.data = data;
		this.errorMessage = errorMessage;
		this.loading = loading;
	}
}
