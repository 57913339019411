import { CognitoUser } from '@aws-amplify/auth';
import { Button, Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PasswordInput from 'common/form/passwordInput';
import TextInput from 'common/form/textInput';
import { useForm } from 'common/form/useForm';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StoreContext } from 'stores';

const useStyles = makeStyles((theme) => ({
    passwordInput: {
        width: '100%',
    },
    submitButton: {
        marginTop: theme.spacing(2),
    },
    subtitle: {
        marginTop: theme.spacing(2),
    },
}));

interface ChallengeParam {
    requiredAttributes: string[];
    userAttributes: any;
}

export interface NewPasswordPageProps {}

const NewPasswordPage: React.FunctionComponent<NewPasswordPageProps> = () => {
    const styles = useStyles();
    const location = useLocation();
    const { userStore } = React.useContext(StoreContext);
    const [error, setError] = React.useState('');
    const navigate = useNavigate();

    const [user, setUser] = React.useState<CognitoUser | any | undefined>(undefined);

    React.useEffect(() => {
        if (location && location.state && (location.state as any).user) {
            setUser((location.state as any).user);
        }
        return () => {};
    }, [setUser, location]);

    const { handleSubmit, handleInputChange, inputs } = useForm(
        async (obj) => {
            if (obj.password !== obj.verifyPassword) {
                setError('Kodeordene er ikke ens');
                return false;
            }

            const challengeParam: ChallengeParam = (user as any).challengeParam;

            const fields = challengeParam.requiredAttributes.reduce((curr, next) => {
                return { ...curr, [next]: obj[next] };
            }, {});

            try {
                await userStore.completeNewPassword(obj.password, fields, () => {
                    navigate('/');
                });
                return true;
            } catch (ex: any) {
                setError(ex);
                return false;
            }
        },
        {
            password: '',
            verifyPassword: '',
            user,
        }
    );

    if (!user) {
        // navigate('/logon');
        return <div>Ingen bruger</div>;
    }

    const challengeParam: ChallengeParam = (user as any).challengeParam;

    return (
        <React.Fragment>
            <Container maxWidth="xs">
                <Typography gutterBottom variant="h5">
                    Reset Kodeord
                </Typography>
                <Typography variant="body1">Du skal have et nyt kodeord til din konto.</Typography>
                <div>
                    <PasswordInput autoFocus={true} customClassName={styles.passwordInput} fieldName="password" label="Kodeord" object={inputs} onChange={handleInputChange} />
                    <PasswordInput customClassName={styles.passwordInput} fieldName="verifyPassword" label="Gentag Kodeord" object={inputs} onChange={handleInputChange} />

                    <Typography className={styles.subtitle} gutterBottom variant="h5">
                        Bekræft oplysninger
                    </Typography>

                    {challengeParam.requiredAttributes.map((attr) => (
                        <TextInput key={attr} className={styles.passwordInput} fieldName={attr} label={attr} object={inputs} onChange={handleInputChange} />
                    ))}

                    <Button className={styles.submitButton} type="submit" fullWidth variant="contained" color="primary" onClick={handleSubmit}>
                        Sæt kodeord
                    </Button>

                    {error && (
                        <Typography variant="body2" color="error">
                            {error}
                        </Typography>
                    )}
                </div>
            </Container>
        </React.Fragment>
    );
};

export default NewPasswordPage;
