import * as React from 'react';
import { Grid, CircularProgress, Typography } from '@mui/material';

export interface IActivityIndicatorProps {
    message?: string;
    style?: React.CSSProperties;
}

const ActivityIndicator: React.FunctionComponent<IActivityIndicatorProps> = ({ message, style }) => {
    return (
        <div style={style ?? { margin: '2em' }}>
            <Grid container justifyContent="center">
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
            {message && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="body1">{message}</Typography>
                </div>
            )}
        </div>
    );
};

export default ActivityIndicator;
