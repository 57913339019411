import SectionModel from 'models/sectionModel';

export default class CatalogSectionCellViewModel {
    public title: string;
    public subtitle: string;
    public sectionId: string;

    constructor(sectionModel: SectionModel) {
        this.title = sectionModel.name;
        this.subtitle = `Fra #${sectionModel.fromLot} til #${sectionModel.lots.reduce((curr, next) => (curr.catalogNumber > next.catalogNumber ? curr : next))!.catalogNumber} - ${
            sectionModel.lots.length
        } lots`;
        this.sectionId = `${sectionModel.id}`;
    }
}
