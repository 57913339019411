import * as React from 'react';
import clsx from 'clsx';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { amber, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Snackbar } from '@mui/material';

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon
};

const useStyles1 = makeStyles((theme: Theme) => ({
	success: {
		backgroundColor: green[600]
	},
	error: {
		backgroundColor: theme.palette.error.dark
	},
	info: {
		backgroundColor: theme.palette.primary.main
	},
	warning: {
		backgroundColor: amber[700]
	},
	icon: {
		fontSize: 20
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1)
	},
	message: {
		display: 'flex',
		alignItems: 'center'
	}
}));

export interface Props {
	className?: string;
	message?: string;
	onClose?: () => void;
	variant: keyof typeof variantIcon;
	open: boolean;
}

export function StyledSnackbar(props: Props) {
	const classes = useStyles1();
	const { className, message, onClose, variant, open, ...other } = props;
	const Icon = variantIcon[variant];

	return (
        <Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left'
			}}
			open={open}
			autoHideDuration={6000}
			onClose={props.onClose}
		>
			<SnackbarContent
				className={clsx(classes[variant], className)}
				aria-describedby="client-snackbar"
				message={
					<span id="client-snackbar" className={classes.message}>
						<Icon className={clsx(classes.icon, classes.iconVariant)} />
						{message}
					</span>
				}
				action={[
					<IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        onClick={onClose}
                        size="large">
						<CloseIcon className={classes.icon} />
					</IconButton>
				]}
				{...other}
			/>
		</Snackbar>
    );
}
