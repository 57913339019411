import React from 'react';
import { StoreContext } from 'stores';
import FutureAuctionsWidget from './futureAuctionsWidget';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import AuctionDto from 'models/backend/AuctionDto';

const useStyles = makeStyles((theme) => ({
    widget: {
        marginBottom: theme.spacing(2),
    },
}));

interface SidebarProps {}

const Sidebar: React.FunctionComponent<SidebarProps> = observer((props) => {
    const styles = useStyles();
    const { auctionStore } = React.useContext(StoreContext);

    const futureAuctions = auctionStore.futureAuctions();
    if (!futureAuctions) {
        return null;
    }

    return (
        <div>
            {futureAuctions.length > 0 &&
                futureAuctions.map((x: AuctionDto) => (
                    <div key={x.id} className={styles.widget}>
                        <FutureAuctionsWidget auction={x} />
                    </div>
                ))}
        </div>
    );
});

export default Sidebar;
