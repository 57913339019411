import { Button, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alerter, AlerterVariant } from 'views/alerter';

const useStyles = makeStyles((theme) => ({
    input: {
        width: '100%',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
}));

interface GetVerificationNameProps {
    username: string;
    completed: (code: string) => void;
}

interface ICodeDeliveryDetails {
    AttributeName: string;
    DeliveryMedium: string;
    Destination: string;
}

interface ICodeResponse {
    CodeDeliveryDetails: ICodeDeliveryDetails;
}

const GetVerificationCode: React.FunctionComponent<GetVerificationNameProps> = (props) => {
    const styles = useStyles(props);
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [verificationCode, setVerificationCode] = React.useState('');
    const [deliveryDetails, setDeliveryDetails] = React.useState<ICodeDeliveryDetails | undefined>(undefined);
    const { t } = useTranslation();

    const SendCode = React.useCallback(async () => {
        setError(undefined);
        try {
            const result: ICodeResponse = await Auth.forgotPassword(props.username);
            setDeliveryDetails(result.CodeDeliveryDetails);
        } catch (ex: any) {
            if (ex.code === 'LimitExceededException') {
                setError(t('forgot_password.too_many_code_requests'));
            } else {
                setError(ex.message);
            }
        }
    }, [props.username, t]);

    React.useEffect(() => {
        SendCode();
    }, [SendCode]);

    const validCode = (code: string) => {
        const regexp = new RegExp(/^\d{6}$/);
        return regexp.test(String(code));
    };

    const codeIsValid = validCode(verificationCode);

    return (
        <React.Fragment>
            <Typography variant="body1">
                {deliveryDetails?.DeliveryMedium.toLowerCase() === 'email' && t('forgot_password.code_sent_to_email', { email: deliveryDetails.Destination })}
                {deliveryDetails?.DeliveryMedium.toLowerCase() === 'sms' && t('forgot_password.code_sent_to_phone', { phoneNumber: deliveryDetails.Destination })}
                <br />
                {deliveryDetails?.DeliveryMedium.toLowerCase() === 'email' && t('forgot_password.code_check_spam')}
            </Typography>
            {deliveryDetails && (
                <TextField
                    className={styles.input}
                    style={{ marginBottom: '1em' }}
                    label="Kode"
                    variant="outlined"
                    onChange={(e) => {
                        const newCode = e.currentTarget.value;
                        setVerificationCode(newCode);
                    }}
                />
            )}
            {deliveryDetails && (
                <Typography variant="body1" gutterBottom style={{ marginBottom: '1em' }}>
                    {t('forgot_password.code_no_mail')}
                </Typography>
            )}
            <Button color="primary" variant="contained" disabled={!codeIsValid} onClick={() => props.completed(verificationCode)}>
                {t('forgot_password.code_continue')}
            </Button>

            <Button
                variant="contained"
                style={{ float: 'right' }}
                onClick={async () => {
                    setError(undefined);
                    try {
                        await Auth.forgotPassword(props.username);
                    } catch (ex: any) {
                        if (ex.code === 'LimitExceededException') {
                            setError(t('forgot_password.too_many_code_requests'));
                        } else {
                            setError(ex.message);
                        }
                    }
                }}
            >
                {t('forgot_password.code_retry')}
            </Button>

            {error && (
                <Alerter style={{ marginTop: '1em' }} variant={AlerterVariant.error}>
                    {error}
                </Alerter>
            )}
        </React.Fragment>
    );
};

export default GetVerificationCode;
