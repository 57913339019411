import { AxiosError } from 'axios';
import { get } from 'common/network';
import * as React from 'react';

class ResponseValues<T> {
    data: T | undefined;
    loading: boolean;
    errorMessage: string | undefined;
    statusCode: number | undefined;

    constructor(data: T | undefined, loading: boolean, errorMessage: string | undefined, statusCode: number | undefined) {
        this.data = data;
        this.loading = loading;
        this.errorMessage = errorMessage;
        this.statusCode = statusCode;
    }
}

export default function useRequest<T>(host: string | undefined, path: string, authenticated: boolean): ResponseValues<T> {
    const [data, setData] = React.useState(new ResponseValues<T>(undefined, true, undefined, undefined));

    if (data.loading) {
        get<T>(host, path, authenticated)
            .then((data: T) => {
                setData(new ResponseValues<T>(data, false, undefined, undefined));
            })
            .catch((ex: AxiosError) => {
                console.error(ex);
                if (ex.response) {
                    if (ex.response.data) {
                        setData(new ResponseValues<T>(undefined, false, ex.response.data.message as string, ex.response.status));
                    } else {
                        setData(new ResponseValues<T>(undefined, false, `${ex.response.status} - ${ex.response.statusText}`, ex.response.status));
                    }
                } else {
                    setData(new ResponseValues<T>(undefined, false, ex.message, undefined));
                }
            });
    }

    return data;
}
