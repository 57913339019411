import { IAuctionPurchases } from '../../my_purchases/models/IPurchasedAuction';
import { MyAuctionPurchasesCellViewModel } from './MyAuctionPurchasesCellViewModel';

export class MyAuctionPurchasesViewModel {
	title: string;
	cellViewModels: MyAuctionPurchasesCellViewModel[];

	constructor(purchases: IAuctionPurchases) {
		const date = new Date(purchases.date.replace(' ', 'T'));
		this.title = `Købt på auktionen: ${purchases.title} den ${date.toLocaleDateString()}`;

		this.cellViewModels = purchases.lots
			.sort((x, y) => x.catalogNumber - y.catalogNumber)
			.map((x) => new MyAuctionPurchasesCellViewModel(x));
	}
}
