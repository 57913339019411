import currency_formatter from 'common/utilities/currency_formatter';
import CatalogModel from 'models/backend/catalogModel';
import LotModel from 'models/backend/lotBackendModel';
import React from 'react';

export default class CatalogSectionLotCellViewModel {
    public id: string;
    public title: React.ReactNode;
    public text: string;
    public soldText: React.ReactNode;
    public imageUrl?: string;
    public imageBackgroundColor?: string;
    public imageWidth: number;
    public imageHeight: number;
    public detailsUrl: string;
    public valuation?: string;
    public catalogNumber: number;
    public note: string | undefined;

    constructor(catalog: CatalogModel, public archived: boolean, public onlineAuction: boolean, lotModel: LotModel) {
        this.id = String(lotModel.id);
        this.catalogNumber = lotModel.catalogNumber;

        this.title = (
            <React.Fragment>
                #{lotModel.catalogNumber}
                {lotModel.creator && (
                    <React.Fragment>
                        : <b>{lotModel.creator}</b>
                    </React.Fragment>
                )}
                {!archived && lotModel.copyDanRestricted && <sup>/CD</sup>}
                {!archived && lotModel.fullVAT && <React.Fragment>*</React.Fragment>}
            </React.Fragment>
        );

        this.text = lotModel.text;
        this.note = lotModel.lotNote;
        if (this.note && this.note.length === 0) {
            this.note = undefined;
        }

        if (lotModel.fullVAT) {
            this.note = this.note ? this.note : '';
            this.note += '\nBemærk at der skal betales fuld moms ved køb, hvilket indebærer 20% salær samt 25% moms af såvel budsum som salær';
        }

        if (lotModel.copyDanRestricted) {
            this.note = this.note ? this.note : '';
            this.note += '\nBemærk at der udover salær skal betales 5% ekstra af hensyn til CopyDan';
        }

        if (lotModel.images[0]) {
            this.imageUrl = lotModel.images[0].thumbnail;
            this.imageWidth = lotModel.images[0].imageWidth;
            this.imageHeight = lotModel.images[0].imageHeight;
        } else if (lotModel.copyDanRestricted) {
            this.imageUrl = 'https://s3.eu-central-1.amazonaws.com/assets.holstebroauktioner.com/Placeholder.jpg';
            this.imageBackgroundColor = 'black';
            this.imageWidth = 125;
            this.imageHeight = 100;
        } else {
            this.imageUrl = undefined;
            this.imageWidth = 0;
            this.imageHeight = 0;
        }

        this.valuation = lotModel.valuation ? `${currency_formatter(lotModel.valuation)}` : undefined;

        if (archived) {
            this.soldText = (
                <React.Fragment>
                    {lotModel.didSell && <span>{`Salgspris: ${currency_formatter(lotModel.price!)}`}</span>}
                    {lotModel.didSell && lotModel.soldWithOtherLots && lotModel.soldWithOtherLots.length > 0 && (
                        <React.Fragment>
                            <br />
                            <span>
                                Lagt sammen med{' '}
                                {lotModel.soldWithOtherLots
                                    .map((lotId) => catalog.lots.find((x) => x.id === lotId)!.catalogNumber)
                                    .map((x) => `#${x}`)
                                    .join(', ')}{' '}
                                under auktionen
                            </span>
                        </React.Fragment>
                    )}
                </React.Fragment>
            );
        } else {
            this.soldText = <span />;
        }

        this.detailsUrl = `/catalog/${catalog.id}/lots/${lotModel.id}`;
    }
}
