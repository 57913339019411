import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import config from 'utilities/config';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import SwitchLocale from './views/switchLocale';

const useStyles = makeStyles((theme) =>
    createStyles({
        item: {
            padding: '1em 1em 4em 1em',
        },
        footer: {
            backgroundColor: '#E3E3E8',
            display: 'block',
        },
        list: {
            listStyleType: 'none',
            margin: 0,
            padding: 0,
        },
        address: {},
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'initial',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
    })
);

export interface BottomBarMobileProps {
    className: string;
}

const BottomBarMobile: React.FunctionComponent<BottomBarMobileProps> = (props) => {
    return (
        <div className={props.className}>
            <div style={{ backgroundColor: 'black', color: 'white', width: '100%', padding: '0.2em', position: 'fixed', bottom: '0%' }}>
                <div style={{ float: 'right' }}>
                    <SwitchLocale compact={true} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="caption">
                        Stationsvej 76 - 7500 Holstebro -{' '}
                        <a style={{ color: 'white', cursor: 'pointer' }} href="tel:+4597401111">
                            (+45) 97 40 11 11
                        </a>{' '}
                        -{' '}
                        <a style={{ color: 'white', cursor: 'pointer' }} href="mailto:mail@ho-auk.dk">
                            Email
                        </a>
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export interface BottomBarDesktopProps {
    className: string;
}

const BottomBarDesktop: React.FunctionComponent<BottomBarDesktopProps> = (props) => {
    return (
        <div className={props.className}>
            <div style={{ display: 'block', backgroundColor: 'black', color: 'white', width: '100%', padding: '0.2em', position: 'fixed', bottom: '0%' }}>
                <div style={{ float: 'right' }}>
                    <SwitchLocale compact={false} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="caption">
                        Holstebro Auktioner - Stationsvej 76 - 7500 Holstebro -{' '}
                        <a style={{ color: 'white', cursor: 'pointer' }} href="tel:+4597401111">
                            (+45) 97 40 11 11
                        </a>{' '}
                        -{' '}
                        <a style={{ color: 'white' }} href="mailto:mail@ho-auk.dk">
                            Mail
                        </a>
                    </Typography>
                </div>
            </div>
        </div>
    );
};

// export default BottomBarMobile;

export interface BottomBarProps {}

const BottomBar: React.FunctionComponent<BottomBarProps> = () => {
    const styling = useStyles({});
    const { t } = useTranslation();
    const email = config().company.email;

    if (config().features.newBottomBar) {
        return (
            <footer className={styling.footer}>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'stretch' }}>
                    <div className={styling.item}>
                        <Typography variant="h6" gutterBottom>
                            Auktionen
                        </Typography>
                        <ul className={styling.list}>
                            <li>
                                <Typography variant="body1">Køb på auktion</Typography>
                            </li>
                            <li>
                                <Typography variant="body1">Salg på auktion</Typography>
                            </li>
                        </ul>
                    </div>
                    <div className={styling.item}>
                        <Typography variant="h4" gutterBottom>
                            Vi tilbyder
                        </Typography>
                        <ul className={styling.list}>
                            <li>Vurderinger</li>
                            <li>Online auktioner</li>
                            <li>Fysiske auktioner</li>
                        </ul>
                    </div>
                    <div className={styling.item}>
                        <address className={styling.address}>
                            <Typography variant="h4" gutterBottom>
                                {t('bottombar.findus.title')}
                            </Typography>
                            {t('address.name')}
                            <br />
                            {t('address.road')}
                            <br />
                            {t('address.postal')}
                            <br />
                            <a href={`mailto:${email}`}>{t('generic.email')}</a>
                        </address>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <SwitchLocale compact={false} />
                </div>
            </footer>
        );
    } else {
        return (
            <Fragment>
                <BottomBarDesktop className={styling.sectionDesktop} />
                <BottomBarMobile className={styling.sectionMobile} />
            </Fragment>
        );
    }
};

export default BottomBar;
