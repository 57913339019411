import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import ActivityIndicator from 'common/views/activityIndicator';
import Title from 'common/views/title';
import DefaultMargin from 'components/DefaultMargin';
import NetworkErrorView from 'components/networkErrorView';
import NoDataDialog from 'components/noDataDialog';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from 'stores';
import { MyPurchasesViewModel } from './models/MyPurchasesViewModel';

export interface MyPurchasesProps {}

const MyPurchases: React.FunctionComponent<MyPurchasesProps> = observer(() => {
    const navigate = useNavigate();
    const { purchaseStore } = React.useContext(StoreContext);
    const purchases = purchaseStore.getPurchases();

    if (purchases.loading) {
        return <ActivityIndicator message="Finder dine køb" />;
    }

    if (purchases.errorMessage) {
        return <NetworkErrorView errorMessage={purchases.errorMessage} />;
    }

    const viewModel = new MyPurchasesViewModel(purchases.data!);
    if (viewModel.cellViewModels.length === 0) {
        return <NoDataDialog title="Mine Køb">Du har ingen registrerede køb på hjemmesiden</NoDataDialog>;
    }

    return (
        <DefaultMargin>
            <Title>Mine Køb</Title>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Titel</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {viewModel.cellViewModels.map((auction) => (
                            <TableRow onClick={() => navigate(`my_purchases/${auction.id}`)} style={{ cursor: 'pointer' }} key={auction.id}>
                                <TableCell>
                                    <Typography variant="body1">{auction.title}</Typography>
                                    <Typography variant="body2">{auction.subtitle}</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </DefaultMargin>
    );
});

export default MyPurchases;
