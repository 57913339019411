import { IPurchasedLot } from '../../my_purchases/models/IPurchasedAuction';
import currencyFormatter from 'common/utilities/currency_formatter';
import { truncate } from 'common/utilities/truncate';

export class MyAuctionPurchasesCellViewModel {
	id: string;
	text: string;
	catalogNumber: number;
	price: string;

	constructor(lot: IPurchasedLot) {
		this.id = lot.lotId;
		this.text = truncate(lot.text, 50);
		this.price = currencyFormatter(lot.price, lot.currency);
		this.catalogNumber = lot.catalogNumber;
	}
}
