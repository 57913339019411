import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography } from '@mui/material';

export interface FirstBidProps {
	nextMinimumBid: number;
}

const FirstBid: React.FunctionComponent<FirstBidProps> = observer((props) => {
	return (
		<React.Fragment>
			<Typography variant="h5" component="h2">
				Bud
			</Typography>
			<Typography variant="body1" component="h2">
				Vær den første til at lægge et bud
			</Typography>
		</React.Fragment>
	);
});

export default FirstBid;
