import * as React from 'react';

export function useForm(callback: (object: any) => Promise<boolean>, initialValue: any = {}) {
	const [inputs, setInputs] = React.useState(initialValue);
	const handleSubmit = async (event: any) => {
		if (event) {
			event.preventDefault();
		}

		const clearInputs = await callback(inputs);
		if (clearInputs) {
			setInputs({});
		}
	};

	const handleInputChange = (event: any) => {
		event.persist();
		const value = event.target.type === 'checkbox' ? event.target.value !== 'true' : event.target.value;
		// console.debug(`Setting field '${event.target.name}' to '${value}'`);
		setInputs((inputs: any) => ({ ...inputs, [event.target.name]: value }));
	};

	return {
		handleSubmit,
		handleInputChange,
		inputs
	};
}
