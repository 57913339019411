import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import * as React from 'react';
import { IActiveAuctionBidsDto } from '../Dtos/IActiveAuctionBidsDto';
import BidRow from './bidRow';

export interface MyBidsListProps {
    auction: IActiveAuctionBidsDto;
}

const MyBidsList: React.FunctionComponent<MyBidsListProps> = ({ auction }) => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Lot #</TableCell>
                    <TableCell align="left">Tekst</TableCell>
                    <TableCell align="right">Dit Max Bud</TableCell>
                    <TableCell align="right">Nuværende Vindende Bud</TableCell>
                    <TableCell align="right"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {auction.userBids.map((userBid) => (
                    <BidRow key={userBid.bid.catalogId + userBid.bid.lotId} userBid={userBid} />
                ))}
            </TableBody>
        </Table>
    );
};

export default MyBidsList;
