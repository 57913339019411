import { Paper, Table, TableBody } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import CatalogSectionCellViewModel from '../viewModels/catalogSectionCellViewModel';
import SectionCell from './sectionCell';

export interface SectionsProps {
    catalogId: string;
    sections: CatalogSectionCellViewModel[];
}

const Sections: React.FunctionComponent<SectionsProps> = (props) => {
    const navigate = useNavigate();

    const rowSelected = (sectionId: string) => {
        navigate(`/catalog/${props.catalogId}/section/${sectionId}`);
    };

    return (
        <Paper>
            <Table>
                <TableBody>
                    {props.sections.map((section) => (
                        <SectionCell title={section.title} subtitle={section.subtitle} sectionId={section.sectionId} selected={rowSelected} key={section.sectionId} />
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

export default Sections;
