import * as React from 'react';
import { Typography } from '@mui/material';
import currency_formatter from 'common/utilities/currency_formatter';

export interface WinningBidProps {
	myMaxBid: number;
	highestBid: number;
	nextMinimumBid: number;
}

const WinningBid: React.FunctionComponent<WinningBidProps> = (props) => {
	return (
		<React.Fragment>
			<Typography gutterBottom variant="h5" component="h2">
				Du har det højeste bud
			</Typography>
			<Typography variant="body1" color="textPrimary" component="p">
				Det nuværende vindende bud er {currency_formatter(props.highestBid)}, og dit max bud går til{' '}
				{currency_formatter(props.myMaxBid)}
			</Typography>
		</React.Fragment>
	);
};

export default WinningBid;
