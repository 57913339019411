import React from 'react';
import { Typography, Button, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PasswordRules from './components/passwordRules';

const useStyles = makeStyles((theme) => ({
    input: {
        width: '100%',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
}));

interface GetPasswordProps {
    completed: (password: string) => void;
}

const GetPassword: React.FunctionComponent<GetPasswordProps> = (props) => {
    const styles = useStyles(props);
    const [password, setPassword] = React.useState('');
    const [passwordVerify, setPasswordVerify] = React.useState('');
    const [passwordValid, setPasswordValid] = React.useState(false);

    return (
        <React.Fragment>
            <Typography variant="body1">Indtast nu dit ønskede kodeord til kontoen</Typography>
            <TextField
                className={styles.input}
                label="Kodeord"
                type="password"
                margin="dense"
                value={password}
                onChange={(e: any) => {
                    const newPassword = e.currentTarget.value;
                    setPassword(newPassword);
                }}
            />
            <TextField
                className={styles.input}
                label="Gentag kodeord"
                type="password"
                margin="dense"
                value={passwordVerify}
                onChange={(e: any) => {
                    const newPassword = e.currentTarget.value;
                    setPasswordVerify(newPassword);
                }}
            />

            <PasswordRules passwordValid={setPasswordValid} password={password} verifyPassword={passwordVerify} />

            <Button color="primary" variant="contained" style={{ marginTop: '1em' }} disabled={!passwordValid} onClick={() => props.completed(password)}>
                Gå Videre
            </Button>
        </React.Fragment>
    );
};

export default GetPassword;
