import { observable } from 'mobx';
import { IStore } from './index';

export enum Severity {
	Information,
	Error
}

export class ToastStore {
	constructor(private rootStore: IStore) {}

	@observable message: string | undefined;

	showMessage(message: string, severity: Severity) {
		this.message = message;
	}
}
