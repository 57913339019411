import { get, post } from 'common/network';
import { action, autorun, computed, makeObservable, observable } from 'mobx';
import config from 'utilities/config';
import { IBidStatusDto, isEqual } from './../models/dtos/IBidStatusDto';
import { IStore } from './index';
import { UserStore } from './UserStore';

class BidType {
    catalogId: string;
    lotId: string;
    bid: number;
    maxBid: boolean;
    catalogNumber: string;
    text: string;

    constructor(catalogId: string, lotId: string, bid: number, maxBid: boolean, catalogNumber: string, text: string) {
        this.catalogId = catalogId;
        this.lotId = lotId;
        this.bid = bid;
        this.maxBid = maxBid;
        this.catalogNumber = catalogNumber;
        this.text = text;
    }
}

interface Listeners {
    catalogId: string;
    lotId: string;
}

interface BidSectionStatusIndex {
    catalogId: string;
    sectionId: string;
}

export class BiddingStore {
    private userStore: UserStore;

    constructor(private rootStore: IStore) {
        this.userStore = rootStore.userStore;
        this.reloadListeners();
        makeObservable(this);

        autorun(() => {
            if (!rootStore.userStore.isAuthenticated) {
                this.biddingStatus.clear();
            }
        });
    }

    reloadListeners() {
        setTimeout(() => {
            this.updateListeners();
        }, config().bidUpdateFrequency);
    }

    @action async updateListeners() {
        if (this.listeners.length === 0) {
            return;
        }

        const response = await post(undefined, `consignmentbids/batch_status`, this.listeners, this.userStore.isAuthenticated);
        const batchBidStatus = response.data as IBidStatusDto[];
        batchBidStatus.forEach((x) => {
            this.updateBidStatus(x.catalogId, x.lotId, x);
        });

        setTimeout(() => {
            this.updateListeners();
        }, config().bidUpdateFrequency);
    }

    updateBidStatus(catalogId: string, lotId: string, bidStatus: IBidStatusDto) {
        const bidsInCatalog = this.biddingStatus.get(catalogId);
        if (bidsInCatalog) {
            const existingBid = bidsInCatalog.get(lotId);
            if (existingBid && isEqual(existingBid, bidStatus)) {
                return;
            }

            bidsInCatalog.set(lotId, bidStatus);
        } else {
            const lots = new Map<string, IBidStatusDto>();
            lots.set(lotId, bidStatus);
            this.biddingStatus.set(catalogId, lots);
        }
    }

    getBidStatus(catalogId: string, lotId: string): IBidStatusDto | undefined {
        let existingLots = this.biddingStatus.get(catalogId);
        return existingLots?.get(lotId);
    }

    @observable private biddingStatus = new Map<string, Map<string, IBidStatusDto>>();
    private hasLoadedSection = new Array<BidSectionStatusIndex>();
    private listeners = new Array<Listeners>();

    @action async loadBidStatus(catalogId: string, lotId: string) {
        const response = await get<IBidStatusDto>(undefined, `catalogs/${catalogId}/lots/${lotId}/bids/status`, this.userStore.isAuthenticated);
        console.debug(`Loaded bid status for ${catalogId} -> ${lotId}`, response);

        this.updateBidStatus(catalogId, lotId, response);
    }

    @action private async loadSectionBidStatus(catalogId: string, sectionId: string) {
        const batchBidStatus = await get<IBidStatusDto[]>(undefined, `consignmentbids/batch_status/catalogs/${catalogId}/sections/${sectionId}`, this.userStore.isAuthenticated);
        batchBidStatus.forEach((x) => {
            this.updateBidStatus(x.catalogId, x.lotId, x);
        });
    }

    bidStatus(catalogId: string, lotId: string): IBidStatusDto | undefined {
        const resultKey = this.getBidStatus(catalogId, lotId);
        return resultKey;
    }

    batchBidStatusForSection(catalogId: string, sectionId: string): undefined | IBidStatusDto[] {
        if (this.hasLoadedSection.some((x) => x.catalogId === catalogId && x.sectionId === sectionId)) {
            const bidsInCatalog = this.biddingStatus.get(catalogId);
            if (bidsInCatalog) {
                return Array.from(bidsInCatalog.values());
            } else {
                return [];
            }
        } else {
            this.hasLoadedSection.push({ catalogId, sectionId });
            this.loadSectionBidStatus(catalogId, sectionId);
            return Array.from(this.biddingStatus.get(catalogId)?.values() ?? []);
        }
    }

    @observable currentBid?: BidType;
    @observable errorMessage: string = '';

    @computed get isQuickBidding(): boolean {
        return this.currentBid !== undefined && !this.currentBid.maxBid;
    }

    @computed get isMaxBidding(): boolean {
        return this.currentBid !== undefined && this.currentBid.maxBid;
    }

    setQuickBid(catalogId: string, lotId: string, bid: number, catalogNumber: string, text: string) {
        this.currentBid = new BidType(catalogId, lotId, bid, false, catalogNumber, text);
    }

    startMaxBid(catalogId: string, lotId: string, bid: number, catalogNumber: string, text: string) {
        this.currentBid = new BidType(catalogId, lotId, bid, true, catalogNumber, text);
    }

    async confirmBid(bid: BidType, notifyOnOverbid: boolean) {
        try {
            this.endBidding();

            await post(undefined, `catalogs/${bid.catalogId}/lots/${bid.lotId}/bids`, {
                bid: bid.bid,
                notifyOnOverbid,
            });

            await this.loadBidStatus(bid.catalogId, bid.lotId);
        } catch (ex: any) {
            if (ex.response) {
                this.errorMessage = ex.response.data;
            } else if (ex.message) {
                this.errorMessage = ex.message;
            } else {
                this.errorMessage = ex;
            }
        }
    }

    endBidding() {
        this.errorMessage = '';
        this.currentBid = undefined;
    }

    subscribeForBidUpdates(catalogId: string, lotId: string) {
        this.listeners.push({ catalogId, lotId });
    }

    unsubscribeForBidUpdates(catalogId: string, lotId: string) {
        this.listeners = this.listeners.filter((x) => x.catalogId !== catalogId || x.lotId !== lotId);
    }
}
