import CloseIcon from '@mui/icons-material/Close';
import { Snackbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';

type ErrorDialogProps = {
    show: boolean;
    message: string;
    onDismiss: () => void;
};

const ErrorDialog: React.FunctionComponent<ErrorDialogProps> = (props) => {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={props.show}
            autoHideDuration={6000}
            onClose={() => {
                props.onDismiss();
            }}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{props.message}</span>}
            action={[
                <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={() => {
                        props.onDismiss();
                    }}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>,
            ]}
        />
    );
};

export default ErrorDialog;
