export default class CatalogSectionBackendModel {
  public fromLot: number;
  public id: string;
  public title: string;

  constructor(object: any) {
    this.fromLot = object.fromLot;
    this.id = object.id;
    this.title = object.title;
  }
}
