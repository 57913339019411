import { Auth } from 'aws-amplify';
import Axios, { AxiosRequestConfig } from 'axios';
import config from 'utilities/config';

function url(path: string): string {
    if (path.includes('?')) {
        return `${config().server.path}/${path}&admin=${config().admin}`;
    } else {
        return `${config().server.path}/${path}?admin=${config().admin}`;
    }
}

async function handleAuth(options: AxiosRequestConfig | undefined, authenticated: boolean): Promise<AxiosRequestConfig> {
    delete Axios.defaults.headers.common['Authorization'];
    let axiosRequestConfig: AxiosRequestConfig = options ?? {};
    if (authenticated) {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken().getJwtToken();
        if (accessToken) {
            if (axiosRequestConfig.headers) {
                axiosRequestConfig.headers['Authorization'] = accessToken;
            } else {
                axiosRequestConfig.headers = { Authorization: accessToken };
            }
        }
    }

    return axiosRequestConfig;
}

export const get = async <T>(host: string | undefined, path: string, authenticated: boolean, options: AxiosRequestConfig | undefined = undefined): Promise<T> => {
    const config = await handleAuth(options, authenticated);

    var serviceUrl = '';
    if (host) {
        serviceUrl = `${host}/${path}`;
    } else {
        serviceUrl = url(path);
    }

    const result = await Axios.get<T>(serviceUrl, config);
    return result.data;
};

export const put = async (host: string | undefined, path: string, body: any, authenticated: boolean = true, options: AxiosRequestConfig | undefined = undefined) => {
    const config = await handleAuth(options, authenticated);

    var serviceUrl = '';
    if (host) {
        serviceUrl = `${host}/${path}`;
    } else {
        serviceUrl = url(path);
    }


    const result = await Axios.put(serviceUrl, body, config);
    return result;
};

export const post = async (host: string | undefined, path: string, body: any, authenticated: boolean = true, options: AxiosRequestConfig | undefined = undefined) => {
    const config = await handleAuth(options, authenticated);

    var serviceUrl = '';
    if (host) {
        serviceUrl = `${host}/${path}`;
    } else {
        serviceUrl = url(path);
    }

    const result = await Axios.post(serviceUrl, body, config);
    return result;
};

export const doDelete = async (path: string, authenticated: boolean = true, options: AxiosRequestConfig | undefined = undefined) => {
    const config = await handleAuth(options, authenticated);

    const result = await Axios.delete(url(path), config);
    return result;
};
