import { Button, Container, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import TextInput from 'common/form/textInput';
import { useForm } from 'common/form/useForm';
import { get, post } from 'common/network';
import ActivityIndicator from 'common/views/activityIndicator';
import NetworkErrorView from 'components/networkErrorView';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import config from 'utilities/config';

const useStyles = makeStyles((theme) =>
    createStyles({
        input: {
            width: '100%',
        },
    })
);

interface ConfirmIdentityDto {
    attributeName: string;
    deliveryMedium: string;
    destination: string;
}

const queryMaker = (email: string | undefined, cognitoId: string | undefined) => {
    var query = '?';
    var addedParam = false;
    if (email) {
        query += `email=${encodeURIComponent(email)}`;
        addedParam = true;
    }

    if (cognitoId) {
        if (addedParam) {
            query += '&';
            addedParam = false;
        }

        query += `cognitoId=${encodeURIComponent(cognitoId)}`;
    }

    if (!email && !cognitoId) {
        console.error('Neither email or cognito id was provided to the query');
    }

    return query;
};

const ConfirmUserConfirmationCode: React.FunctionComponent = () => {
    const styles = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [identityType, setIdentityType] = React.useState<ConfirmIdentityDto | undefined>(undefined);
    const [error, setError] = React.useState('');

    const locationState = location.state as any;

    const email = locationState.email as string | undefined;
    const cognitoId = locationState.cognitoId as string | undefined;

    const { inputs, handleInputChange, handleSubmit } = useForm(async (obj: any) => {
        try {
            await post(config().services.user, `bidder/confirmIdentity${queryMaker(email, cognitoId)}`, { confirmationCode: obj.confirmationCode }, false);
            navigate('/logon');
        } catch (ex: any) {
            console.error(ex);
            setError(ex.message);
        }
        return false;
    }, {});

    React.useEffect(() => {
        (async () => {
            try {
                const result = await get<ConfirmIdentityDto>(config().services.user, `bidder/confirmIdentity${queryMaker(email, cognitoId)}`, false);
                setIdentityType(result);
            } catch (error) {
                console.log(error);
                setError('Kunne ikke skaffe en kode. Prøv igen. ' + (error as any));
            }
        })();
    }, [email, cognitoId]);

    if (!locationState) {
        return <NetworkErrorView errorMessage="No location state provided" />;
    }

    if (!email && !cognitoId) {
        return <NetworkErrorView errorMessage="Failed to find necessary data from location" />;
    }

    if (!identityType && !error) {
        return <ActivityIndicator />;
    }

    return (
        <React.Fragment>
            <Container maxWidth="xs">
                <Typography variant="h5">Bekræft kode</Typography>
                {identityType && `Indtast koden du har fået på ${identityType.deliveryMedium === 'EMAIL' ? 'email' : identityType.destination}`}
                <TextInput style={{ marginTop: '2em' }} className={styles.input} autoFocus fieldName="confirmationCode" object={inputs} label="Bekræftelseskode" onChange={handleInputChange} />
                <Button disabled={!inputs.confirmationCode || inputs.confirmationCode.length === 0} onClick={handleSubmit} style={{ marginTop: '2em' }} variant="contained" color="primary">
                    Bekræft kode
                </Button>
                <br />
                <Button
                    onClick={() => {
                        get(config().services.user, `bidder/confirmIdentity${queryMaker(email, cognitoId)}}`, false);
                    }}
                    style={{ marginTop: '2em' }}
                    variant="contained"
                    color="secondary"
                >
                    Gensend kode
                </Button>
                {error && (
                    <Typography variant="body2" color="error">
                        {error}
                    </Typography>
                )}
            </Container>
        </React.Fragment>
    );
};

export default ConfirmUserConfirmationCode;
