import { IAuctionPurchases } from './IPurchasedAuction';
export class MyPurchasesCellViewModel {
	id: string;
	title: string;
	subtitle: string;
	constructor(data: IAuctionPurchases) {
		this.id = data.auctionId;
		this.title = data.title;
		this.subtitle = new Date(data.date.replace(' ', 'T')).toLocaleDateString();
	}
}
