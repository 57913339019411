import LotImageBackendModel from './backend/imageBackendModel';

export class SingleLotImage {
	public path: string;
	public imageWidth: number;
	public imageHeight: number;

	constructor(path: string, width: number, height: number) {
		this.path = path;
		this.imageWidth = width;
		this.imageHeight = height;
	}
}

export default class LotImage {
	public original: SingleLotImage;
	public thumbnail: SingleLotImage;
	public order: number;

	private imageModel: LotImageBackendModel;

	constructor(imageModel: LotImageBackendModel) {
		this.original = new SingleLotImage(imageModel.path, imageModel.imageWidth, imageModel.imageHeight);
		this.thumbnail = new SingleLotImage(
			imageModel.thumbnail,
			imageModel.thumbnailImageWidth,
			imageModel.thumbnailImageHeight
		);
		this.order = imageModel.order;
		this.imageModel = imageModel;
	}

	width(height: number): number {
		return (this.imageModel.imageWidth / this.imageModel.imageHeight) * height;
	}

	height(width: number): number {
		return (this.imageModel.imageHeight / this.imageModel.imageWidth) * width;
	}
}
