import { Button, Paper } from '@mui/material';
import Axios from 'axios';
import { useForm } from 'common/form/useForm';
import Title from 'common/views/title';
import DefaultMargin from 'components/DefaultMargin';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StoreContext } from 'stores/index';
import Markdown from 'utilities/markdown';

interface IAcceptTermsLocation {
    path: string;
    complete: () => void;
}

const AcceptTerms: React.FunctionComponent<any> = () => {
    const [markdown, setMarkdown] = React.useState<string | undefined>(undefined);
    const { userStore } = React.useContext(StoreContext);
    const location = useLocation();
    const navigate = useNavigate();

    const { handleSubmit } = useForm(
        async () => {
            userStore.nextLogonPhase(navigate);
            return true;
        },
        { approve: false }
    );

    React.useEffect(() => {
        window.scrollTo(0, 0);
        const loc = location;
        if (!loc) {
            return;
        }

        const state = loc.state as IAcceptTermsLocation;
        if (!state) {
            return;
        }
        const markdownPath = state.path;
        if (!markdownPath) {
            return;
        }

        Axios.get(markdownPath).then((markdown) => {
            setMarkdown(markdown.data);
        });
    }, [setMarkdown, location]);

    if (!markdown) {
        return <div />;
    }

    return (
        <DefaultMargin>
            <Title>Godkend vores købskonditioner</Title>
            <Button style={{ marginBottom: '1em' }} onClick={handleSubmit} variant="contained" color="primary">
                Jeg har læst og godkendt betingelserne
            </Button>
            <br />
            <Paper>
                <Markdown style={{ padding: '1em' }}>{markdown}</Markdown>
            </Paper>
            <Button style={{ marginTop: '1em' }} onClick={handleSubmit} variant="contained" color="primary">
                Jeg har læst og godkendt betingelserne
            </Button>
        </DefaultMargin>
    );
};

export default AcceptTerms;
