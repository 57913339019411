export default function parseQuery(queryString: string): Map<string, string> {
    let queryParams = new Map<string, string>();
    queryString
        .substring(1)
        .split('&')
        .forEach((a) => {
            queryParams.set(a.split('=')[0], a.split('=')[1]);
        });

    return queryParams;
}
