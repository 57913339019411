import currency_formatter from 'common/utilities/currency_formatter';
import CatalogModel from 'models/backend/catalogModel';
import LotModel from 'models/backend/lotBackendModel';
import React from 'react';

export default class LotDescriptionViewModel {
    public text: string;
    public note?: string;
    public soldNote?: React.ReactNode;
    public showBidView: boolean;
    public catalogNumber: string;
    public valuation?: string;

    constructor(auctionCompleted: boolean, public onlineOnlyAuction: boolean, catalog: CatalogModel, lotModel: LotModel) {
        this.text = lotModel.text;
        this.showBidView = !auctionCompleted;

        if (auctionCompleted) {
            this.note = undefined;

            if (lotModel.didSell) {
                this.soldNote = (
                    <React.Fragment>
                        {lotModel.didSell && lotModel.price && <span>{`Salgspris: ${currency_formatter(lotModel.price)}`}</span>}
                        {lotModel.didSell && lotModel.soldWithOtherLots && lotModel.soldWithOtherLots.length > 0 && (
                            <span>
                                <br />
                                Lagt sammen med katalog{' '}
                                {lotModel.soldWithOtherLots
                                    .map((lotId) => catalog.lots.find((x) => x.id === lotId)!.catalogNumber)
                                    .map((x) => `#${x}`)
                                    .join(', ')}{' '}
                                lot under auktionen
                            </span>
                        )}
                    </React.Fragment>
                );
            }
        } else {
            this.note = lotModel.lotNote;
        }

        if (lotModel.fullVAT) {
            this.note = this.note ? this.note : '';
            this.note += '\nBemærk at der skal betales fuld moms ved køb, hvilket indebærer 20% salær samt 25% moms af såvel budsum som salær';
        }

        if (lotModel.copyDanRestricted) {
            this.note = this.note ? this.note : '';
            this.note += '\nBemærk at der udover salær skal betales 5% ekstra af hensyn til CopyDan';
        }

        this.valuation = lotModel.valuation ? `Vurdering: ${currency_formatter(lotModel.valuation)}` : undefined;
        this.catalogNumber = String(lotModel.catalogNumber);
        this.text = lotModel.text;
    }
}
