import { Typography } from '@mui/material';
import AuctionDto from 'models/backend/AuctionDto';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, longDateFormat, sameDay } from 'utilities/dateFormatter';
import { Alerter, AlerterVariant } from 'views/alerter';

export interface AuctionHeaderProps {
    auction: AuctionDto;
}

const AuctionHeader: React.FunctionComponent<AuctionHeaderProps> = (props) => {
    const { auction } = props;
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {auction.completed && auction.archived && auction.date && (
                <Alerter variant={AlerterVariant.warning} style={{ marginBottom: '1rem' }}>
                    {t('auction.archive')}
                    <br />
                    {t('auction.archive_cont', { date: formatDate(auction.date) })}
                </Alerter>
            )}

            {auction.completed && !auction.archived && auction.date && (
                <Alerter variant={AlerterVariant.warning} style={{ marginBottom: '1rem' }}>
                    Budgivning på auktionen er slut - Auktionen afventer hammerslag i auktionssalen
                </Alerter>
            )}

            {!auction.completed && auction.onlineOnlyAuction && auction.biddingDeadline && (
                <Typography variant="body1">{t('auction.online.bidding_expires_from', { date: longDateFormat(auction.biddingDeadline) })}</Typography>
            )}

            {!auction.completed && !auction.onlineOnlyAuction && auction.date && <Typography variant="body1">Auktionen afholdes {longDateFormat(auction.date)}</Typography>}

            {!auction.completed && !auction.onlineOnlyAuction && auction.date && auction.biddingDeadline && sameDay(auction.date, auction.biddingDeadline) && (
                <Typography variant="body1" gutterBottom>
                    Budgivning på hjemmesiden lukker kl {auction.biddingDeadline.toLocaleTimeString('da-DK', { hour: 'numeric', minute: 'numeric' })}
                </Typography>
            )}

            {!auction.completed && !auction.onlineOnlyAuction && auction.date && auction.biddingDeadline && !sameDay(auction.date, auction.biddingDeadline) && (
                <Typography variant="body1" gutterBottom>
                    Budgivning på hjemmesiden lukker {formatDate(auction.biddingDeadline)}
                </Typography>
            )}
        </React.Fragment>
    );
};

export default AuctionHeader;
