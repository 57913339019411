import { Menu, MenuItem } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { StoreContext } from 'stores';
import DesktopLinks from './components/desktopLinks';
import MobileLinks from './components/mobileLinks';

export interface TopBarProps {}

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: 'black',
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
        marginLeft: theme.spacing(1),
        color: 'white',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'initial',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

enum AppPage {
    myProfile = '/my_profile',
    myBids = '/my_bids',
    myPurchases = '/my_purchases',
}

interface ProfileMenuProps {
    menuAnchor: HTMLElement | undefined;
    hideMenu: () => void;
    pageSelected: (page: AppPage) => void;
}

const ProfileMenu: React.FunctionComponent<ProfileMenuProps> = ({ menuAnchor, hideMenu, pageSelected }) => {
    const navigate = useNavigate();
    const { userStore } = React.useContext(StoreContext);
    const { t } = useTranslation();

    return (
        <Menu
            anchorEl={menuAnchor}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={() => {
                hideMenu();
            }}
        >
            <MenuItem
                onClick={() => {
                    pageSelected(AppPage.myProfile);
                    hideMenu();
                }}
            >
                {t('menu.my_profile')}
            </MenuItem>
            <MenuItem
                onClick={() => {
                    pageSelected(AppPage.myBids);
                    hideMenu();
                }}
            >
                {t('menu.current_bids')}
            </MenuItem>
            <MenuItem
                onClick={() => {
                    pageSelected(AppPage.myPurchases);
                    hideMenu();
                }}
            >
                {t('menu.my_purchases')}
            </MenuItem>
            {userStore.isAuthenticated && (
                <MenuItem
                    onClick={() => {
                        userStore.logout();
                        hideMenu();
                        navigate('/');
                    }}
                >
                    {t('generic.logout')}
                </MenuItem>
            )}
        </Menu>
    );
};

const TopBar: React.FunctionComponent<TopBarProps> = observer(() => {
    const styles = useStyles();
    const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | undefined>(undefined);
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <AppBar position="static" color="default" elevation={0} className={styles.appBar}>
            <Toolbar className={styles.toolbar}>
                <img src={require('assets/logo.png')} alt="Logo" height="41" width="23" />

                <Link variant="h6" noWrap className={styles.toolbarTitle} to="/" component={RouterLink}>
                    {t('title')}
                </Link>

                <DesktopLinks showMenu={(item: HTMLElement) => setMenuAnchor(item)} className={styles.sectionDesktop} />
                <MobileLinks className={styles.sectionMobile} />
                <ProfileMenu menuAnchor={menuAnchor} hideMenu={() => setMenuAnchor(undefined)} pageSelected={(url: AppPage) => navigate(url)} />
            </Toolbar>
        </AppBar>
    );
});

export default TopBar;
