import { Typography } from '@mui/material';
import ActivityIndicator from 'common/views/activityIndicator';
import Title from 'common/views/title';
import DefaultMargin from 'components/DefaultMargin';
import NoDataDialog from 'components/noDataDialog';
import { observer } from 'mobx-react-lite';
import AuctionHeader from 'pages/auctionHeader';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { StoreContext } from 'stores';
import parseQuery from 'utilities/queryParse';
import CatalogSectionViewModel from './viewModels/catalogSectionViewModel';
import Lots from './views/lots';

type CatalogSectionPageProps = {
    catalogId: string;
    sectionId: string;
};

const CatalogSectionPage: React.FunctionComponent = observer(() => {
    const { catalogStore, biddingStore } = React.useContext(StoreContext);
    const [page, setPage] = React.useState(0);
    const { t } = useTranslation();
    const location = useLocation();
    const params = useParams<CatalogSectionPageProps>();

    const { catalogId = '', sectionId = '' } = params;
    const catalogResult = catalogStore.catalog(catalogId);

    React.useEffect(() => {
        const queryParams = parseQuery(location.search);
        let pageIndex = 0;
        let queryPage = queryParams.get('page');
        if (queryPage) {
            pageIndex = parseInt(queryPage);
        }
        setPage(pageIndex);

        biddingStore.batchBidStatusForSection(catalogId, sectionId);
    }, [biddingStore, catalogId, sectionId, location]);

    if (!catalogResult) {
        return <ActivityIndicator message="Indlæser katalog" />;
    }

    const catalog = catalogResult.object;
    if (!catalog) {
        return <NoDataDialog title="Fejl">{catalogResult.errorMessage}</NoDataDialog>;
    }

    const auction = catalog.auction;

    const section = catalogStore.section(catalogId, sectionId);
    const viewModel = new CatalogSectionViewModel(catalog, section);

    if (viewModel.isLoading) {
        return <ActivityIndicator message="Indlæser katalog" />;
    }

    const itemsPerPage = 100;

    return (
        <DefaultMargin>
            <Typography variant="overline">
                {auction.onlineOnlyAuction ? t('online_auction') : t('traditional_auction')} - <Link to={`/auction/${auction.id}`}>{auction.title}</Link>
            </Typography>
            <Title>{section?.name}</Title>
            <AuctionHeader auction={auction} />
            <Lots
                itemsPerPage={itemsPerPage}
                lots={viewModel.lots}
                page={page}
                setPage={(newPage) => {
                    setPage(newPage);
                    window.scrollTo(0, 0);
                }}
                catalogId={catalogId}
            />
        </DefaultMargin>
    );
});

export default CatalogSectionPage;
