import { Button } from '@mui/material';
import { useForm } from 'common/form/useForm';
import { put } from 'common/network';
import ActivityIndicator from 'common/views/activityIndicator';
import Title from 'common/views/title';
import DefaultMargin from 'components/DefaultMargin';
import { IBidderDto } from 'models/dtos/IBidderDto';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import config from 'utilities/config';
import ErrorDialog from '../../common/views/errorDialog';
import NetworkErrorView from '../../components/networkErrorView';
import useRequest from '../../utilities/useRequest';
import Profile from './components/profile';

interface IProfileResponse {
    verifyCode: boolean;
    email?: string;
}

const MyProfile: React.FunctionComponent = () => {
    const [error, setError] = React.useState('');
    const navigate = useNavigate();

    const bidderRequest = useRequest<IBidderDto>(config().services.user, 'bidder', true);
    const { inputs, handleInputChange, handleSubmit } = useForm(async (changed: any) => {
        try {
            const result: IProfileResponse = (await put(config().services.user, `bidder`, changed)).data;
            if (result.verifyCode) {
                navigate('/profile/confirm_email', { state: { email: result.email } });
            }

            return false;
        } catch (ex: any) {
            if (ex.response) {
                setError(ex.response.data);
            } else {
                setError(ex.message);
            }

            return false;
        }
    });

    if (bidderRequest.loading) {
        return <ActivityIndicator />;
    }

    if (bidderRequest.errorMessage) {
        return <NetworkErrorView errorMessage={bidderRequest.errorMessage} />;
    }

    const profile = bidderRequest.data!.onlineUser;
    const actualInputs = {
        ...profile,
        ...inputs,
    };

    return (
        <DefaultMargin>
            <ErrorDialog
                show={error !== ''}
                message={error}
                onDismiss={() => {
                    setError('');
                }}
            />
            <Title>Min Profil</Title>
            <Profile actualInputs={actualInputs} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
            <br />
            <Button onClick={handleSubmit} style={{ marginTop: '2em' }} variant="contained" color="primary">
                Gem
            </Button>
        </DefaultMargin>
    );
};

export default MyProfile;
