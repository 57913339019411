import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Button, TableCell, TableRow } from '@mui/material';
import currency_formatter from 'common/utilities/currency_formatter';
import { truncate } from 'common/utilities/truncate';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from 'stores';
import { IActiveAuctionBidsBidDto } from '../Dtos/IActiveAuctionBidsBidDto';

type BidRowProps = {
    userBid: IActiveAuctionBidsBidDto;
};

const BidRow: React.FunctionComponent<BidRowProps> = observer((props) => {
    const navigate = useNavigate();
    const { catalogStore } = React.useContext(StoreContext);
    const userBid = props.userBid;
    const lot = catalogStore.lot(userBid.bid.catalogId, userBid.bid.lotId);
    if (!lot) {
        return <React.Fragment />;
    }

    return (
        <TableRow
            style={{ cursor: 'pointer' }}
            onClick={() => {
                navigate(`/catalog/${userBid.bid.catalogId}/lots/${userBid.bid.lotId}`);
            }}
            key={userBid.bid.lotId}
        >
            <TableCell align="center">{userBid.status.hasWinningBid ? <CheckIcon /> : <CloseIcon />}</TableCell>
            <TableCell component="th" scope="row">
                {lot.catalogNumber}
            </TableCell>
            <TableCell align="left">{truncate(lot.text, 50)}</TableCell>
            <TableCell align="right">{currency_formatter(userBid.bid.bid)}</TableCell>
            <TableCell align="right">{currency_formatter(userBid.status.winningBid!)}</TableCell>
            <TableCell align="right">
                <Button variant="outlined">Detaljer</Button>
            </TableCell>
        </TableRow>
    );
});

export default BidRow;
