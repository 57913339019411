import { ILoginPhase } from './ILoginPhase';
export class CompleteLogonPhase implements ILoginPhase {
    block: () => Promise<void>;
    constructor(block: () => Promise<void>) {
        this.block = block;
    }
    perform() {
        this.block();
    }
}
