import { Paper, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import ArchiveItemViewModel from '../viewModels/archiveItemViewModel';
import ArchiveCell from './archiveCell';

export interface CatalogArchiveTableProps {
    items: ArchiveItemViewModel[];
    page: number;
    setPage: (page: number) => void;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
            overflowX: 'auto',
        },
        table: {
            minWidth: 650,
        },
    })
);

const CatalogArchiveTable: React.FunctionComponent<CatalogArchiveTableProps> = observer((props) => {
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Navn</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.items.map((archive) => (
                        <ArchiveCell archive={archive} key={archive.id} />
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            count={1000}
                            rowsPerPage={10}
                            onPageChange={(_, page) => {
                                props.setPage(page);
                                navigate(`/archive?page=${page}`, { replace: true });
                            }}
                            page={props.page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </Paper>
    );
});

export default CatalogArchiveTable;
