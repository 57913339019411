export class Post {
    id: number;
    title: string;
    body: string;
    image: string | null;

    constructor(public data: any) {
        this.id = data.newsId;
        this.title = data.title;
        this.body = data.text;

        if (data.images.length > 0) {
            this.image = 'https://s3-eu-central-1.amazonaws.com/assets.holstebroauktioner.com/news/' + data.images[0].path;
        } else {
            this.image = null;
        }
    }
}
