import { longDateFormat } from 'utilities/dateFormatter';
import i18n from 'i18n';
import AuctionModel from 'models/backend/AuctionDto';

export default class AuctionCellViewModel {
    public id: string;
    public title: string;
    public subtitle: string;
    public auctionUrl: string;
    public note: string;

    constructor(auctionModel: AuctionModel) {
        this.id = auctionModel.id;
        this.title = auctionModel.title;
        if (auctionModel.onlineOnlyAuction) {
            this.subtitle = auctionModel.biddingDeadline ? i18n.t('auctions.cell.online.title', { date: longDateFormat(auctionModel.biddingDeadline) }) : '';
        } else {
            this.subtitle = auctionModel.date ? i18n.t('auctions.cell.physical.title', { date: longDateFormat(auctionModel.date) }) : '';
        }
        this.auctionUrl = `/auction/${auctionModel.id}`;
        this.note = auctionModel.note;
    }
}
