import ActivityIndicator from 'common/views/activityIndicator';
import Title from 'common/views/title';
import DefaultMargin from 'components/DefaultMargin';
import NoDataDialog from 'components/noDataDialog';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from 'stores';
import AuctionsTable from './views/auctionsTable';

const Auctions: React.FunctionComponent<any> = observer(() => {
    const { auctionStore } = React.useContext(StoreContext);
    const futureAuctions = auctionStore.futureAuctions();
    const { t } = useTranslation();

    if (!futureAuctions) {
        return <ActivityIndicator message="Indlæser auktioner" />;
    }

    if (!futureAuctions || futureAuctions.length === 0) {
        return <NoDataDialog title={t('auctions.none_dialog.title')}>{t('auctions.none_dialog.message')}</NoDataDialog>;
    }

    return (
        <DefaultMargin>
            <Title>{t('auctions.title')}</Title>
            <AuctionsTable />
        </DefaultMargin>
    );
});

export default Auctions;
