import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { grey, lightBlue } from '@mui/material/colors';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import Amplify from 'aws-amplify';
import { observer } from 'mobx-react-lite';
import BottomBar from 'pages/bottombar';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import 'react-image-lightbox/style.css';
import ReactModal from 'react-modal';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { StoreContext } from 'stores';
import usePageTracking from 'usePageTracking';
import config from 'utilities/config';
import { StyledSnackbar } from 'views/styledSnackbar';
import './App.css';
import MaxBidModal from './modals/maxBid';
import QuickBidModal from './modals/quickBid';
import ContentRouting from './pages/contentRouting';
import TopBar from './pages/topbar';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://f4878dcf6e3d482c9cc627e4e582f23a@o359091.ingest.sentry.io/5192630',
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(React.useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
            }),
        ],
        tracesSampleRate: 1.0,
    });
}

ReactModal.setAppElement('#root');

const fonts = ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(',');

const theme = createTheme({
    palette: {
        primary: {
            main: grey[900],
            contrastText: '#ffffff',
        },
        secondary: {
            main: lightBlue[900],
        },
    },
    typography: {
        allVariants: {
            fontFamily: fonts,
        },
    },
});

const App: React.FunctionComponent = observer(() => {
    const { biddingStore } = React.useContext(StoreContext);
    usePageTracking();

    Amplify.configure({
        Auth: {
            region: config().cognito.region,
            userPoolId: config().cognito.poolId,
            userPoolWebClientId: config().cognito.clientId,
            authenticationFlowType: 'USER_PASSWORD_AUTH', // necessary to do cognito migration - should be reverted to USER_SRP_AUTH in the future
        },
    });

    return (
        <Sentry.ErrorBoundary showDialog>
            <ThemeProvider theme={theme}>
                <StyledEngineProvider injectFirst>
                    <CssBaseline />
                    <TopBar />

                    <StyledSnackbar
                        variant="error"
                        open={biddingStore.errorMessage !== ''}
                        message={biddingStore.errorMessage ? biddingStore.errorMessage : ''}
                        onClose={() => {
                            biddingStore.errorMessage = '';
                        }}
                    />

                    <QuickBidModal />
                    <MaxBidModal />
                    <div style={{ marginBottom: '7rem' }}>
                        <ContentRouting />
                    </div>
                    <BottomBar />
                    <CookieConsent buttonText="Accepter">Vi bruger cookies til at forbedre oplevelsen</CookieConsent>
                </StyledEngineProvider>
            </ThemeProvider>
        </Sentry.ErrorBoundary>
    );
});

export default App;
