import { Typography } from '@mui/material';
import * as Sentry from '@sentry/browser';
import { get } from 'common/network';
import { action, makeObservable, observable } from 'mobx';
import { IAuctionDto } from 'models/dtos/IAuctionDto';
import React from 'react';
import AuctionModel from '../models/backend/AuctionDto';
import { Result } from './CatalogStore';
import { IStore } from './index';
import { Severity, ToastStore } from './ToastStore';

export class AuctionStore {
    constructor(private rootStore: IStore, private toastStore: ToastStore) {
        makeObservable(this)
    }

    @observable private auctions = new Map<string, Result<AuctionModel>>();
    private isLoadingAuction = new Array<string>();
    @observable private _futureAuctions?: AuctionModel[];

    futureAuctions(): AuctionModel[] | undefined {
        if (!this._futureAuctions) {
            this.fetchAuctions();
            return undefined;
        } else {
            return this._futureAuctions;
        }
    }

    public auction(auctionId: string): Result<AuctionModel> | undefined {
        if (!this.auctions.has(auctionId)) {
            this.fetchAuction(auctionId);
            return undefined;
        }

        return this.auctions.get(auctionId);
    }

    @action private async fetchAuctions() {
        const result = await get<any[]>(undefined, `auctions/active?pageSize=20&page=1`, false);
        const auctions = result.map((x) => new AuctionModel(x));
        this._futureAuctions = auctions.sort((x, y) => {
            const xDeadline = (x.onlineOnlyAuction ? x.biddingDeadline : x.date) ?? new Date();
            const yDeadline = (y.onlineOnlyAuction ? y.biddingDeadline : y.date) ?? new Date();
            return xDeadline.getTime() - yDeadline.getTime();
        });
    }

    @action private async fetchAuction(auctionId: string) {
        console.log('fetch auction');
        if (this.isLoadingAuction.find((x) => x === auctionId) !== undefined) {
            return;
        }

        this.isLoadingAuction.push(auctionId);

        if (this.auctions.has(auctionId)) {
            return;
        }

        console.debug('loading auction: ' + auctionId);

        try {
            const catalogModel = await get<IAuctionDto>(undefined, `auctions/${auctionId}`, false);
            console.debug('Loaded auction', catalogModel);
            this.auctions.set(auctionId, new Result(new AuctionModel(catalogModel), ''));
        } catch (ex: any) {
            console.debug('Failed to load catalog');
            if (ex.response && ex.response.status === 404) {
                this.auctions.set(
                    auctionId,
                    new Result<AuctionModel>(
                        undefined,
                        (
                            <Typography variant="body1">
                                Vi kunne desværre ikke finde det efterspurgte auction
                                <br />
                                Vi har sendt en besked til vores team som vil undersøge problemet.
                            </Typography>
                        )
                    )
                );
            }

            Sentry.captureMessage(`Failed to find catalog with id: '${auctionId}'`, "error");

            this.errorHandler(ex);
        }

        this.isLoadingAuction = this.isLoadingAuction.filter((x) => x !== auctionId);
    }

    private errorHandler(ex: any): string {
        Sentry.captureException(ex);

        if (ex.response) {
            console.error(ex.response.data);
            this.toastStore.showMessage(ex.response.data, Severity.Error);
            return ex.response.data;
        } else {
            if (ex.message) {
                console.error(ex.message);
                this.toastStore.showMessage(ex.message, Severity.Error);
                return ex.message;
            } else {
                console.error(ex);
                this.toastStore.showMessage(ex, Severity.Error);
                return ex;
            }
        }
    }
}
