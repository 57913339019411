import config from 'utilities/config';
import { get } from './../common/network';
import { IBidderDto } from './../models/dtos/IBidderDto';

export default class ProfileService {
    async current(): Promise<IBidderDto> {
        const profile = await get<IBidderDto>(config().services.user, 'bidder', true);
        return profile;
    }
}
