import React from 'react';
import { Typography, CardMedia, Card } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Post } from '../models/post';

interface FrontpagePostProps {
    post: Post;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginBottom: '2em',
            width: 700,
            maxWidth: '100%',
        },
        divider: {
            marginBottom: theme.spacing(1),
        },
    })
);

const FrontpagePost: React.FunctionComponent<FrontpagePostProps> = (props) => {
    const { post } = props;
    const styles = useStyles();

    return (
        <div className={styles.root}>
            <Card elevation={1}>
                {post.image && (
                    <CardMedia>
                        <img style={{ width: '100%', maxHeight: 1024, marginLeft: 'auto', objectFit: 'cover' }} src={post.image} alt="" />
                    </CardMedia>
                )}
                <div style={{ paddingTop: '8px', paddingLeft: '16px', paddingRight: '16px', paddingBottom: '16px' }}>
                    <Typography variant="h6">{post.title}</Typography>
                    <div dangerouslySetInnerHTML={{ __html: post.body }} />
                </div>
            </Card>
        </div>
    );
};

export default FrontpagePost;
