import { CognitoUser } from '@aws-amplify/auth';
import privacyPolicyPath from 'assets/privacy_policy_dk.md';
import termsAndConditionsPath from 'assets/terms_and_conditions.md';
import { Auth } from 'aws-amplify';
import axios, { AxiosError } from 'axios';
import { action, computed, makeObservable, observable } from 'mobx';
import { NavigateFunction } from 'react-router';
import config from 'utilities/config';
import ProfileService from '../services/profileService';
import { put } from './../common/network';
import { IBidderDto } from './../models/dtos/IBidderDto';
import { IStore } from './index';
import { AcceptTermsPhase } from './models/AcceptTermsPhase';
import { CompleteLogonPhase } from './models/CompleteLogonPhase';
import { ILoginPhase } from './models/ILoginPhase';
import { LogonResult } from './models/LogonResult';
import { UpdateProfilePhase } from './models/UpdateProfilePhase';
import { VerbatimLogonResult } from './models/VerbatimLogonResult';

export class UserStore {
    constructor(private rootStore: IStore) {
        makeObservable(this);

        Auth.currentAuthenticatedUser(undefined)
            .then((x) => this.completeLogon(x, () => {}))
            .catch((x) => {});

        this.setAuthenticationHeader();
    }

    @observable user?: CognitoUser = undefined;
    @observable profile?: IBidderDto = undefined;

    @observable forgotPasswordData?: any = undefined;
    logonPhases: ILoginPhase[] = [];

    @computed get isAuthenticated() {
        return !!this.user;
    }

    @action public async logout() {
        await Auth.signOut();
        this.user = undefined;
        delete axios.defaults.headers.common['Authorization'];
    }

    async setAuthenticationHeader() {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken().getJwtToken();
            if (accessToken) {
                axios.defaults.headers.common['Authorization'] = accessToken;
            } else {
                return this.logout();
            }
        } catch (x) {
            delete axios.defaults.headers.common['Authorization'];
        }
    }

    public async login(username: string, password: string, navigate: NavigateFunction): Promise<VerbatimLogonResult> {
        var user: CognitoUser | any;

        try {
            user = await Auth.signIn({
                username,
                password,
            });
            console.log('user :>> ', user);
        } catch (err: any) {
            console.log('err :>> ', err);
            if (err.code === 'UserNotConfirmedException') {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
                return new VerbatimLogonResult(LogonResult.requiresConfirmation, 'Du skal bekræfte din konto');
            } else if (err.code === 'PasswordResetRequiredException') {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                return new VerbatimLogonResult(LogonResult.passwordResetRequired, 'Du mangler at nulstille dit kodeord');
            } else if (err.code === 'NotAuthorizedException') {
                // The error happens when the incorrect password is provided
                return new VerbatimLogonResult(LogonResult.wrongPassword, 'Du har indtastet et forkert kodeord');
            } else if (err.code === 'UserNotFoundException') {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
                return new VerbatimLogonResult(LogonResult.userDoesNotExist, 'Kunne ikke finde din bruger');
            } else if (err.code === 'InvalidParameterException') {
                // The error happens when a user has been created directly in the Cognito web interface using import users feature
                return new VerbatimLogonResult(LogonResult.passwordResetRequired, 'Du skal nulstille din email');
            } else {
                return new VerbatimLogonResult(LogonResult.failure, 'Vi kunne desværre ikke logge Dem ind. Er De sikker på at de har indtastet det rigtige brugernavn og kodeord.');
            }
        }

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            return new VerbatimLogonResult(LogonResult.newPassword, '', user);
        } else {
            try {
                return await this.completeLogon(user, navigate);
            } catch (ex) {
                console.error(ex);
                return new VerbatimLogonResult(LogonResult.failure, 'Vi kunne desværre ikke logge Dem ind. Der ser ud til at være en teknisk fejl.');
            }
        }
    }

    async completeNewPassword(password: string, requiredAttributes: any, navigate: NavigateFunction) {
        const newUser = await Auth.completeNewPassword(this.user, password, requiredAttributes);
        this.completeLogon(newUser, navigate);
    }

    private async completeLogon(user: CognitoUser | any, navigate: NavigateFunction): Promise<VerbatimLogonResult> {
        let profile: IBidderDto | undefined = undefined;

        try {
            profile = await new ProfileService().current();
        } catch (error) {
            console.warn('failed to login');
            const axiosError = error as AxiosError;
            console.log(axiosError);
            if (axiosError.response && axiosError.response.status === 404) {
                const phases: ILoginPhase[] = [
                    new AcceptTermsPhase(termsAndConditionsPath),
                    new AcceptTermsPhase(privacyPolicyPath),
                    new UpdateProfilePhase(),
                    new CompleteLogonPhase(async () => {
                        try {
                            await put(config().services.user, 'acceptTerms', {});
                            this.user = user;
                            navigate('/');
                        } catch (ex: any) {
                            console.error(ex);
                        }
                    }),
                ];

                this.logonPhases = phases;
                const firstPhase = this.logonPhases.shift()!;
                firstPhase.perform(navigate);
                return new VerbatimLogonResult(LogonResult.acceptTerms, '');
            }

            throw error;
        }

        this.profile = profile;

        if (profile === undefined) {
            return new VerbatimLogonResult(LogonResult.failure, 'Kunne ikke finde bruger (code: 1)');
        }

        if (profile.onlineUser === undefined) {
            return new VerbatimLogonResult(LogonResult.failure, 'Brugeren har desværre ingen online profil (code: 2)');
        }

        if (profile.onlineUser.acceptedTermsVersion === undefined || profile.onlineUser.acceptedTermsVersion === 0) {
            console.log('no accepted terms version');
            const phases: ILoginPhase[] = [
                new AcceptTermsPhase(termsAndConditionsPath),
                new AcceptTermsPhase(privacyPolicyPath),
                new UpdateProfilePhase(),
                new CompleteLogonPhase(async () => {
                    try {
                        await put(config().services.user, 'acceptTerms', {});
                        this.user = user;
                        navigate('/');
                    } catch (ex: any) {
                        console.error(ex);
                    }
                }),
            ];

            this.logonPhases = phases;
            const firstPhase = this.logonPhases.shift()!;
            console.log('navigate :>> ', navigate);
            firstPhase.perform(navigate);
            return new VerbatimLogonResult(LogonResult.acceptTerms, '');
        }

        console.log('terms accepted');

        this.user = user;
        return new VerbatimLogonResult(LogonResult.success, '');
    }

    nextLogonPhase(navigate: NavigateFunction) {
        const nextPhase = this.logonPhases.shift();
        if (!nextPhase) {
            return false;
        }

        nextPhase.perform(navigate);
    }
}
