import { Button, CardActions, CardContent, Divider, Paper, Typography } from '@mui/material';
import currency_formatter from 'common/utilities/currency_formatter';
import ActivityIndicator from 'common/views/activityIndicator';
import BidCountdown from 'components/bidCountdown';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from 'stores/index';
import FirstBid from './firstBid';
import FirstBidForUser from './firstBidForUser';
import LosingBid from './losingBid';
import WinningBid from './winningBid';

export interface BidViewProps {
    catalogId: string;
    lotId: string;
    catalogNumber: string;
    text: string;
    onlineOnlyAuction: boolean;
    style?: React.CSSProperties;
}

const BidView: React.FunctionComponent<BidViewProps> = observer((props) => {
    const { biddingStore, userStore } = React.useContext(StoreContext);
    const [countdownDone, setCountdownDone] = React.useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    React.useEffect(() => {
        biddingStore.loadBidStatus(props.catalogId, props.lotId);
    }, [biddingStore, props.catalogId, props.lotId]);

    React.useEffect(() => {
        biddingStore.subscribeForBidUpdates(props.catalogId, props.lotId);

        return () => {
            biddingStore.unsubscribeForBidUpdates(props.catalogId, props.lotId);
        };
    });

    const bidStatus = biddingStore.bidStatus(props.catalogId, props.lotId);

    if (!bidStatus) {
        return <ActivityIndicator style={props.style} />;
    }

    const showBidding = bidStatus && bidStatus.canBid ? bidStatus && bidStatus.canBid : false;
    if (!showBidding) {
        return <div />;
    }

    const currentDate = new Date();
    const biddingDeadline = bidStatus && new Date(bidStatus.biddingDeadline);

    if (biddingDeadline < currentDate || countdownDone) {
        return (
            <Paper style={props.style}>
                <Typography style={{ padding: '1em' }} variant="body1">
                    {t('bidding_over_for_lot')}
                </Typography>
            </Paper>
        );
    }

    const userHasPlacedBid = bidStatus.usersMaxBid && bidStatus.usersMaxBid > 0 ? true : false;
    const hasBids = bidStatus.hasBids;

    const canBid = biddingDeadline ? biddingDeadline > currentDate : false;

    const countdownComponent = (
        <React.Fragment>
            <Typography style={{ marginTop: '1em', fontWeight: 'bold' }} variant="body2" color="textPrimary" component="p">
                {biddingDeadline && props.onlineOnlyAuction && canBid && (
                    <React.Fragment>
                        Budgivning slutter om{' '}
                        <BidCountdown
                            biddingDeadline={biddingDeadline}
                            onDone={() => {
                                setCountdownDone(true);
                            }}
                        />
                    </React.Fragment>
                )}
                {biddingDeadline && !canBid && 'Budgivning er slut'}
            </Typography>
        </React.Fragment>
    );

    if (userHasPlacedBid && bidStatus.hasWinningBid) {
        return (
            <Paper style={props.style}>
                <CardContent>
                    {bidStatus.usersMaxBid && <WinningBid highestBid={bidStatus.winningBid!} myMaxBid={bidStatus.usersMaxBid} nextMinimumBid={bidStatus.nextMinimumBid} />}
                    {countdownComponent}
                </CardContent>
                <Divider />
                {canBid && (
                    <CardActions>
                        <Button
                            size="medium"
                            onClick={() => {
                                biddingStore.startMaxBid(props.catalogId, props.lotId, bidStatus.nextMinimumBid, props.catalogNumber, props.text);
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Forhøj max bud
                        </Button>
                    </CardActions>
                )}
            </Paper>
        );
    }

    return (
        <Paper style={props.style}>
            <CardContent>
                {!userHasPlacedBid && hasBids && <FirstBidForUser currentBid={bidStatus.winningBid!} nextMinimumBid={bidStatus.nextMinimumBid} />}
                {!hasBids && <FirstBid nextMinimumBid={bidStatus.nextMinimumBid} />}
                {userHasPlacedBid && bidStatus.hasWinningBid && <WinningBid highestBid={bidStatus.winningBid!} myMaxBid={bidStatus.usersMaxBid!} nextMinimumBid={bidStatus.nextMinimumBid} />}
                {userHasPlacedBid && !bidStatus.hasWinningBid && <LosingBid myMaxBid={bidStatus.usersMaxBid!} highestBid={bidStatus.winningBid!} nextMinimumBid={bidStatus.nextMinimumBid} />}
                {countdownComponent}
            </CardContent>
            <Divider />
            <CardActions>
                {!userStore.isAuthenticated && (
                    <Button
                        size="medium"
                        onClick={() => {
                            navigate('/logon');
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Log ind for at byde
                    </Button>
                )}
                {userStore.isAuthenticated && (
                    <Button
                        size="medium"
                        onClick={() => {
                            biddingStore.setQuickBid(props.catalogId, props.lotId, bidStatus.nextMinimumBid, props.catalogNumber, props.text);
                        }}
                        variant="contained"
                        color="primary"
                    >
                        Byd {currency_formatter(bidStatus.nextMinimumBid)}
                    </Button>
                )}
                {userStore.isAuthenticated && (
                    <Button
                        size="medium"
                        onClick={() => {
                            biddingStore.startMaxBid(props.catalogId, props.lotId, bidStatus.nextMinimumBid, props.catalogNumber, props.text);
                        }}
                        variant="contained"
                        color="secondary"
                    >
                        Sæt max bud
                    </Button>
                )}
            </CardActions>
        </Paper>
    );
});

export default BidView;
