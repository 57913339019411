import * as React from 'react';
import { Grid } from '@mui/material';
import { StoreContext } from 'stores';
import { observer } from 'mobx-react-lite';
import AuctionCellViewModel from '../viewModels/auctionCellViewModel';
import AuctionCell from './auctionCell';
import AuctionDto from 'models/backend/AuctionDto';

export interface AuctionsTableProps {}

const AuctionsTable: React.FunctionComponent<AuctionsTableProps> = observer(() => {
    const { auctionStore } = React.useContext(StoreContext);
    const futureAuctions = auctionStore.futureAuctions();

    if (!futureAuctions) {
        return <React.Fragment />;
    }

    const futureAuctionsViewModels = futureAuctions.map((x: AuctionDto) => new AuctionCellViewModel(x));

    return (
        <Grid container spacing={2}>
            {futureAuctionsViewModels.map((viewModel: AuctionCellViewModel) => (
                <Grid key={viewModel.id} item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <AuctionCell viewModel={viewModel} />
                </Grid>
            ))}
        </Grid>
    );
});

export default AuctionsTable;
