function currency_format(value: number, currency: string = 'DKK'): string {
    const formatter = new Intl.NumberFormat('da-DK', {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
    });

    return formatter.format(value);
};


export default currency_format;