import { Button, Container, Divider, Paper, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { post } from 'common/network';
import DefaultMargin from 'components/DefaultMargin';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import config from 'utilities/config';
import { Alerter, AlerterVariant } from 'views/alerter';

type ConfirmEmailProps = {
    email: string;
};

const useStyles = makeStyles((theme) => ({
    input: {
        width: '100%',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
}));

const ConfirmEmail: React.FunctionComponent = () => {
    const [verificationCode, setVerificationCode] = React.useState('');
    const [error, setError] = React.useState<string | undefined>(undefined);
    const { t } = useTranslation();
    const styles = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const email = location && (location.state as ConfirmEmailProps).email;

    const validCode = (code: string) => {
        const regexp = new RegExp(/^\d{6}$/);
        return regexp.test(String(code));
    };

    const codeIsValid = validCode(verificationCode);

    return (
        <DefaultMargin>
            <Container maxWidth="xs">
                <Paper style={{ padding: '1em' }}>
                    <Typography gutterBottom variant="h5">
                        Bekræft Email
                    </Typography>
                    <Divider style={{ marginBottom: '1em' }} />
                    <Typography variant="body1">Der er blevet sendt en kode til {email}. Indsæt den i feltet nedenfor.</Typography>
                    <TextField
                        className={styles.input}
                        style={{ marginBottom: '1em' }}
                        label="Kode"
                        variant="outlined"
                        onChange={(e) => {
                            const newCode = e.currentTarget.value;
                            setVerificationCode(newCode);
                        }}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!codeIsValid}
                        onClick={async () => {
                            try {
                                await post(config().services.user, `bidder/confirmIdentity&email=${encodeURIComponent(email)}`, { confirmationCode: verificationCode });
                                navigate('/my_profile');
                            } catch (ex: any) {
                                console.debug(ex);
                                setError('Forkert kode. Prøv igen.');
                            }
                        }}
                    >
                        {t('forgot_password.code_continue')}
                    </Button>
                    {error && (
                        <Alerter style={{ marginTop: '1em' }} variant={AlerterVariant.error}>
                            {error}
                        </Alerter>
                    )}
                </Paper>
            </Container>
        </DefaultMargin>
    );
};

export default ConfirmEmail;
