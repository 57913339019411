import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

export interface PagerProps {
    page: number;
    setPage: (page: number) => void;
    totalCount: number;
    itemsPerPage: number;
}

const Pager: React.FunctionComponent<PagerProps> = observer((props) => {
    const itemsPerPage = props.itemsPerPage;
    if (itemsPerPage > props.totalCount) {
        return null;
    }

    const morePages = props.page * itemsPerPage + itemsPerPage < props.totalCount;

    return (
        <div style={{ padding: '1em', float: 'right' }}>
            <Button
                onClick={() => {
                    const newPage = props.page - 1;
                    props.setPage(Math.max(newPage, 0));
                }}
                variant="contained"
                style={{ marginRight: '1em' }}
                startIcon={<NavigateBeforeIcon />}
                disabled={props.page === 0}
            >
                Forrige
            </Button>
            <Button
                onClick={() => {
                    const newPage = props.page + 1;
                    props.setPage(Math.min(newPage, Math.floor(props.totalCount / itemsPerPage)));
                }}
                variant="contained"
                endIcon={<NavigateNextIcon />}
                disabled={!morePages}
            >
                Næste
            </Button>
        </div>
    );
});

export default Pager;
