import { NavigateFunction } from 'react-router';
import { ILoginPhase } from './ILoginPhase';

export class AcceptTermsPhase implements ILoginPhase {
    pageName: string = '/logon/accept_terms';
    markdownPath: string;
    constructor(markdownAssetPath: string) {
        this.markdownPath = markdownAssetPath;
    }

    perform(navigate: NavigateFunction) {
        navigate(this.pageName, {
            state: {
                path: this.markdownPath,
            },
        });
    }
}
