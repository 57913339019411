import * as React from 'react';
import { Typography } from '@mui/material';

export interface NetworkErrorViewProps {
	errorMessage: string;
}

const NetworkErrorView: React.FunctionComponent<NetworkErrorViewProps> = ({ errorMessage }) => {
	return (
		<React.Fragment>
			<Typography variant="h5">Der opstod en fejl på siden</Typography>
			<Typography variant="caption">Teknisk fejl: {errorMessage}</Typography>{' '}
		</React.Fragment>
	);
};

export default NetworkErrorView;
