import React from 'react';

interface ImageProps {
	mode?: ImageModes;
	preferredHeight?: number;
	preferredWidth?: number;
	image: string;
	imageWidth: number;
	imageHeight: number;
}

export enum ImageModes {
	fill = 'cover',
	fit = 'contain'
}

export const Image: React.FunctionComponent<ImageProps> = (props) => {
	let { image, preferredHeight, preferredWidth, imageWidth, imageHeight } = props;

	let size: string = props.mode || 'contain';

	let calcWidth = 0,
		calcHeight = 0;
	if (preferredHeight) {
		calcHeight = preferredHeight;
		calcWidth = (imageWidth / imageHeight) * preferredHeight;
	}

	if (preferredWidth) {
		calcWidth = preferredWidth;
		calcHeight = (imageHeight / imageWidth) * preferredWidth;
	}

	let defaults = {
		height: calcHeight,
		width: calcWidth,
		backgroundColor: 'clear'
	};

	let important = {
		backgroundImage: `url("${image}")`,
		backgroundSize: size,
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat'
	};

	return <div style={{ ...defaults, ...important }} />;
};
