import { TextField } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface ProfileProps {
    actualInputs: any;
    handleInputChange: (event: any) => void;
    handleSubmit: (event: any) => Promise<void>;
}

const Profile: React.FunctionComponent<ProfileProps> = ({ actualInputs, handleInputChange, handleSubmit }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <TextField name="firstName" label={t('create_user.firstname')} value={actualInputs.firstName} onChange={handleInputChange} />
            <br />
            <TextField style={{ marginTop: '1em' }} name="lastName" label={t('create_user.lastname')} value={actualInputs.lastName} onChange={handleInputChange} />
            <br />
            <TextField style={{ marginTop: '1em' }} name="companyName" label={t('create_user.company_name')} value={actualInputs.companyName} onChange={handleInputChange} />
            <br />
            <TextField style={{ marginTop: '1em' }} name="streetName" label="Adresse" value={actualInputs.streetName} onChange={handleInputChange} />
            <br />
            <TextField style={{ marginTop: '1em' }} name="postalCode" label="Postnummer" value={actualInputs.postalCode} onChange={handleInputChange} />
            <br />
            <TextField style={{ marginTop: '1em' }} name="city" label="By" value={actualInputs.city} onChange={handleInputChange} />
            <br />
            <TextField style={{ marginTop: '1em' }} name="country" label="Land" value={actualInputs.country} onChange={handleInputChange} />
            <br />
            <TextField style={{ marginTop: '1em' }} name="province" label="Provins" value={actualInputs.province} onChange={handleInputChange} />
        </React.Fragment>
    );
};

export default Profile;
