import CatalogModel from '../../../models/backend/catalogModel';
import CatalogSectionViewModel from './catalogSectionCellViewModel';
import SectionModel from 'models/sectionModel';
import lotsForSection from 'utilities/lots_for_section';

export default class CatalogViewModel {
    public isLoading: boolean;
    public sections: CatalogSectionViewModel[] = [];

    constructor(catalogModel: CatalogModel | null) {
        this.isLoading = catalogModel === null;

        if (!catalogModel) {
            return;
        }

        this.sections = catalogModel.sections
            .map((x) => {
                let sectionResult = lotsForSection(catalogModel, x.id);
                if (sectionResult.object !== undefined) {
                    return new SectionModel(sectionResult.object.section, sectionResult.object.lots);
                } else {
                    return null;
                }
            })
            .filter((x) => x !== null)
            .map((x) => x as SectionModel)
            .map((x) => new CatalogSectionViewModel(x));
    }
}
