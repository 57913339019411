import * as Sentry from '@sentry/browser';
import { get } from 'common/network';
import { action, autorun, makeObservable, observable } from 'mobx';
import { IMyBidsResponse } from '../pages/my_bids/models/IMyBidsResponse';
import { IStore } from './index';
import { Response } from './models/Response';
import { Severity, ToastStore } from './ToastStore';

export class MyBidsStore {
    toastStore: ToastStore;

    constructor(private rootStore: IStore, toastStore: ToastStore) {
        this.toastStore = toastStore;
        makeObservable(this)

        autorun(() => {
            if (!rootStore.userStore.isAuthenticated) {
                this.bids = new Response<IMyBidsResponse>(undefined, undefined, false);
            }
        });
    }

    @observable bids: Response<IMyBidsResponse> = new Response<IMyBidsResponse>(undefined, undefined, false);

    public getBids() {
        if (!this.bids.data && !this.bids.errorMessage) {
            this.fetchBids();
        }

        return this.bids;
    }

    @action private async fetchBids() {
        this.bids = new Response<IMyBidsResponse>(undefined, undefined, true);
        try {
            const response = await get<IMyBidsResponse>(undefined, 'profile/activeBids', true);
            this.bids = new Response<IMyBidsResponse>(response, undefined, false);
        } catch (ex: any) {
            const error = this.errorHandler(ex);
            this.bids = new Response<IMyBidsResponse>(undefined, error, false);
        }
    }

    private errorHandler(ex: any): string {
        Sentry.captureException(ex);
        if (ex.response) {
            console.error(ex.response.data);
            this.toastStore.showMessage(ex.response.data, Severity.Error);
            return ex.response.data;
        } else {
            console.error(ex.message);
            this.toastStore.showMessage(ex.message, Severity.Error);
            return ex.message;
        }
    }
}
