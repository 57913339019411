import { Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ActivityIndicator from 'common/views/activityIndicator';
import NoDataDialog from 'components/noDataDialog';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useParams } from 'react-router';
import { StoreContext } from 'stores';
import CatalogViewModel from './viewModels/catalogViewModel';
import Sections from './views/sections';

const useStyles = makeStyles((theme) =>
    createStyles({
        sectionTitle: {
            marginBottom: theme.spacing(1),
        },
    })
);

type CatalogProps = {
    catalogId: string;
};

const Catalog: React.FunctionComponent<CatalogProps> = observer((props) => {
    const { catalogStore } = React.useContext(StoreContext);
    const params = useParams<CatalogProps>();
    let { catalogId } = params;
    if (catalogId === undefined) {
        catalogId = props.catalogId as string;
    }

    const catalogResult = catalogStore.catalog(catalogId);
    const styles = useStyles();

    if (!catalogResult) {
        return <ActivityIndicator message="Indlæser katalog" />;
    }

    const catalog = catalogResult.object;
    if (!catalog) {
        return <NoDataDialog title="Fejl">{catalogResult.errorMessage}</NoDataDialog>;
    }

    const viewModel = new CatalogViewModel(catalog);

    return (
        <React.Fragment>
            <Typography variant="h6" className={styles.sectionTitle}>
                Katalog:
            </Typography>
            <Sections sections={viewModel.sections} catalogId={catalogId} />
        </React.Fragment>
    );
});

export default Catalog;
