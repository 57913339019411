import { Button, Typography } from '@mui/material';
import ActivityIndicator from 'common/views/activityIndicator';
import Title from 'common/views/title';
import DefaultMargin from 'components/DefaultMargin';
import NetworkErrorView from 'components/networkErrorView';
import { IBidderDto } from 'models/dtos/IBidderDto';
import Profile from 'pages/my_profile/components/profile';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { StoreContext } from 'stores';
import config from 'utilities/config';
import { useForm } from '../../common/form/useForm';
import { put } from '../../common/network';
import ErrorDialog from '../../common/views/errorDialog';
import useRequest from '../../utilities/useRequest';

export interface LogonPhaseUpdateProfileProps {}

const LogonPhaseUpdateProfile: React.FunctionComponent<LogonPhaseUpdateProfileProps> = () => {
    const [error, setError] = React.useState('');
    const { userStore } = React.useContext(StoreContext);
    const navigate = useNavigate();

    const result = useRequest<IBidderDto>(config().services.user, 'bidder', true);

    const { inputs, handleInputChange, handleSubmit } = useForm(async (changed: any) => {
        try {
            if (Object.keys(changed).length > 0) {
                await put(config().services.user, `bidder`, changed);
            }

            userStore.nextLogonPhase(navigate);
            return false;
        } catch (ex: any) {
            if (ex.response) {
                setError(ex.response.data);
            } else {
                setError(ex.message);
            }

            return false;
        }
    });

    if (result.loading) {
        return <ActivityIndicator />;
    }

    // a 404 means no user could be found, so we need all the data
    if (result.errorMessage && result.statusCode !== 404) {
        console.error(result);
        if (typeof result.errorMessage === 'string') {
            return <NetworkErrorView errorMessage={result.errorMessage} />;
        } else {
            console.error(result);
            return <NetworkErrorView errorMessage={'Kunne desværre ikke kalde vores backend'} />;
        }
    }

    if (!result.data && result.statusCode !== 404) {
        console.error(result);
        return <NetworkErrorView errorMessage={'Fejlkode: 1'} />;
    }

    const profile = result.data?.onlineUser;
    const actualInputs = {
        ...profile,
        ...inputs,
    };

    return (
        <DefaultMargin>
            <ErrorDialog
                show={error !== ''}
                message={error}
                onDismiss={() => {
                    setError('');
                }}
            />
            <Title>Opdater profil</Title>
            <Typography variant="body1">Du bedes bekræfte de profil oplysninger vi har på dig</Typography>
            <Profile actualInputs={actualInputs} handleInputChange={handleInputChange} handleSubmit={handleSubmit}></Profile>
            <br />
            <Button color="primary" variant="outlined" style={{ marginTop: '1em' }} onClick={handleSubmit}>
                Accepter
            </Button>
        </DefaultMargin>
    );
};

export default LogonPhaseUpdateProfile;
