import { Button, Divider, Paper, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formatDate, formatViewingDate } from 'utilities/dateFormatter';
import AuctionModel from '../../../models/backend/AuctionDto';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(1, 2, 1, 2),
            maxWidth: 400,
            cursor: 'pointer',
        },
    })
);

export interface FutureAuctionsWidgetProps {
    auction: AuctionModel;
}

const FutureAuctionsWidget: React.FunctionComponent<FutureAuctionsWidgetProps> = ({ auction }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Paper className={classes.root} onClick={() => navigate(`/auction/${auction.id}`)}>
            <Typography variant="overline">{auction.onlineOnlyAuction ? t('online_auction') : t('traditional_auction')}</Typography>
            <Typography variant="h6" style={{ marginBottom: 0, lineHeight: 1.2 }}>
                {auction.title}
            </Typography>

            {auction.onlineOnlyAuction && auction.biddingDeadline && (
                <Typography variant="subtitle1" gutterBottom>
                    {<React.Fragment>{t('frontpage.futureAuctionsWidget.online.bidding_expires_at', { date: formatDate(auction.biddingDeadline) })}</React.Fragment>}
                </Typography>
            )}

            {!auction.onlineOnlyAuction && auction.date && (
                <Typography variant="subtitle1" gutterBottom>
                    {t('frontpage.futureAuctionsWidget.physical.auction_starts_at', { date: formatDate(auction.date) })}
                </Typography>
            )}

            {/* {auction.viewings.length === 1 && auction.viewings[0] && (
                <React.Fragment>
                    <Typography variant="subtitle1" gutterBottom>
                        {t('frontpage.futureAuctionsWidget.viewing', { date: formatViewingDate(auction.viewings[0].from, auction.viewings[0].to, false) })}
                    </Typography>
                </React.Fragment>
            )} */}

            {auction.viewings.length > 0 && (
                <React.Fragment>
                    <Typography variant="subtitle2" style={{ marginTop: '0.5rem' }}>
                        {t('frontpage.futureAuctionsWidget.viewings')}:
                    </Typography>
                    <ul style={{ padding: 0, margin: 0, listStyleType: 'none', listStyle: 'none' }}>
                        {auction.viewings
                            .sort((x, y) => x.from.getTime() - y.from.getTime())
                            .map((viewing, index) => (
                                <React.Fragment key={index}>
                                    <li style={{ margin: 0 }}>
                                        <Typography variant="body1">{formatViewingDate(viewing.from, viewing.to, true)}</Typography>
                                    </li>
                                </React.Fragment>
                            ))}
                    </ul>
                </React.Fragment>
            )}

            {auction.note && <Typography variant="body1">{auction.note}</Typography>}

            <React.Fragment>
                <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
                <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0.5rem' }}>
                    <Button variant="outlined" onClick={() => navigate(`/auction/${auction.id}`)} color="primary" size="small">
                        {t('frontpage.futureAuctionsWidget.see_details')}
                    </Button>
                </div>
            </React.Fragment>
        </Paper>
    );
};

export default FutureAuctionsWidget;
