import React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
    listItem: {
        marginTop: theme.spacing(1),
    },
});

const options = {
    overrides: {
        h1: {
            component: (props) => <Typography gutterBottom variant="h4" {...props} />,
        },
        h2: {
            component: (props) => <Typography gutterBottom variant="h6" {...props} />,
        },
        h3: {
            component: (props) => <Typography gutterBottom style={{ fontWeight: 'bold' }} variant="subtitle1" {...props} />,
        },
        h4: {
            component: (props) => <Typography gutterBottom variant="caption" paragraph {...props} />,
        },
        p: { component: (props) => <Typography paragraph {...props} /> },
        // eslint-disable-next-line
        a: { component: (props) => <a {...props} style={{ textDecoration: 'underline', color: 'black' }} /> },
        li: {
            component: withStyles(styles)(({ classes, ...props }) => (
                <li className={classes.listItem}>
                    <Typography component="span" {...props} />
                </li>
            )),
        },
    },
};

export default function Markdown(props) {
    return <ReactMarkdown options={options} {...props} />;
}
