import * as React from 'react';
import Title from 'common/views/title';
import DefaultMargin from './DefaultMargin';

export interface NoDataDialogProps {
    title: string;
    children: React.ReactNode;
}

const NoDataDialog: React.FunctionComponent<NoDataDialogProps> = (props) => {
    return (
        <DefaultMargin>
            <Title>{props.title}</Title>
            {props.children}
        </DefaultMargin>
    );
};

export default NoDataDialog;
