import { Link, Typography } from '@mui/material';
import AuctionModel from 'models/backend/AuctionDto';
import CatalogModel from 'models/backend/catalogModel';
import LotModel from 'models/backend/lotBackendModel';
import LotImage from 'models/lotImage';
import * as React from 'react';
import { formatDate } from 'utilities/dateFormatter';
import LotDescriptionViewModel from './lotDescriptionViewModel';

export default class LotDetailsViewModel {
    public title: React.ReactNode;
    public subtitle?: React.ReactNode;
    public promotedImage: LotImage | undefined;
    public lotDescriptionViewModel: LotDescriptionViewModel;
    public images: LotImage[];
    public showImages: boolean = true;
    public imageText?: string;

    constructor(catalogModel: CatalogModel, lotModel: LotModel, auctionModel: AuctionModel, navigate: (path: string) => void) {
        this.title = (
            <React.Fragment>
                # {lotModel.catalogNumber}
                {lotModel.creator && <React.Fragment>: {lotModel.creator}</React.Fragment>}
                {!auctionModel.archived && lotModel.fullVAT && <React.Fragment>*</React.Fragment>}
                {!auctionModel.archived && lotModel.copyDanRestricted && (
                    <React.Fragment>
                        <sup>/CD</sup>
                    </React.Fragment>
                )}
            </React.Fragment>
        );

        this.lotDescriptionViewModel = new LotDescriptionViewModel(auctionModel.completed, auctionModel.onlineOnlyAuction, catalogModel, lotModel);

        if (auctionModel.completed) {
            const auctionLink = (
                <Link style={{ cursor: 'pointer' }} onClick={() => navigate(`/auction/${auctionModel.id}`)}>
                    {auctionModel.title}
                </Link>
            );

            this.subtitle = (
                <div>
                    <Typography variant="caption">
                        Bemærk at dette er fra et tidligere katalog '{auctionLink}' der blev afholdt d. {formatDate(auctionModel.date!)}
                    </Typography>
                </div>
            );

            this.showImages = !lotModel.copyDanRestricted;
            this.imageText = lotModel.copyDanRestricted ? 'Billeder vises ikke da de er CopyDan beskyttet' : undefined;
        } else {
            this.subtitle = undefined;
        }

        if (lotModel.images.length > 0) {
            const promoImage = lotModel.images.reduce((curr, next) => (curr.order < next.order ? curr : next));
            this.promotedImage = new LotImage(promoImage);
        } else {
            this.promotedImage = undefined;
        }

        this.images = lotModel.images.map((x) => new LotImage(x)).sort((x, y) => x.order - y.order);
    }
}
