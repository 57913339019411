import * as Sentry from '@sentry/browser';
import { get } from 'common/network';
import { action, autorun, makeObservable, observable } from 'mobx';
import { IAuctionPurchases } from '../pages/my_purchases/models/IPurchasedAuction';
import { IStore } from './index';
import { Response } from './models/Response';
import { Severity, ToastStore } from './ToastStore';

export class PurchaseStore {
    toastStore: ToastStore;

    constructor(private rootStore: IStore, toastStore: ToastStore) {
        this.toastStore = toastStore;
        makeObservable(this)

        autorun(() => {
            if (!rootStore.userStore.isAuthenticated) {
                this.purchases = new Response<IAuctionPurchases[]>(undefined, undefined, false);
            }
        });
    }

    @observable purchases: Response<IAuctionPurchases[]> = new Response<IAuctionPurchases[]>(undefined, undefined, false);

    public getPurchases() {
        if (!this.purchases.data && !this.purchases.errorMessage) {
            this.fetchPurchases();
        }

        return this.purchases;
    }

    public getAuctionPurchases(auctionId: string): Response<IAuctionPurchases> {
        if (!this.purchases.data && !this.purchases.errorMessage) {
            this.fetchPurchases();
        }

        if (this.purchases.data) {
            const auction = this.purchases.data.find((x) => x.auctionId === auctionId);
            if (auction) {
                return new Response<IAuctionPurchases>(auction, undefined, false);
            } else {
                return new Response<IAuctionPurchases>(undefined, 'No data', false);
            }
        } else {
            return new Response<IAuctionPurchases>(undefined, undefined, true);
        }
    }

    @action private async fetchPurchases() {
        this.purchases = new Response<IAuctionPurchases[]>(undefined, undefined, true);
        try {
            const purchaseResponse = await get<IAuctionPurchases[]>(undefined, 'profile/purchases', true);
            this.purchases = new Response<IAuctionPurchases[]>(purchaseResponse, undefined, false);
        } catch (ex: any) {
            const error = this.errorHandler(ex);
            this.purchases = new Response<IAuctionPurchases[]>(undefined, error, false);
        }
    }

    private errorHandler(ex: any): string {
        Sentry.captureException(ex);
        if (ex.response) {
            console.error(ex.response.data);
            this.toastStore.showMessage(ex.response.data, Severity.Error);
            return ex.response.data;
        } else {
            console.error(ex.message);
            this.toastStore.showMessage(ex.message, Severity.Error);
            return ex.message;
        }
    }
}
