import { get } from 'common/network';
import { action, makeObservable, observable } from 'mobx';
import ArchiveItemViewModel from 'pages/archive/viewModels/archiveItemViewModel';
import { IStore } from './index';

export class ArchiveStore {
    constructor(private rootStore: IStore) {
        makeObservable(this)
    }

    @observable public page = 0;

    @observable catalogs = new Map<number, ArchiveItemViewModel[]>();
    @observable isLoading = new Map<number, boolean>();

    public get_page(pageNumber: number): ArchiveItemViewModel[] | undefined {
        if (!this.catalogs.has(pageNumber)) {
            this.fetchPage(pageNumber);
            return undefined;
        }

        return this.catalogs.get(pageNumber);
    }

    @action async fetchPage(page: number) {
        if (this.isLoading.get(page)) {
            return;
        }

        this.isLoading.set(page, true);

        const url = `auctions/archive?pageSize=10&page=${page + 1}`;

        const result = await get<any[]>(undefined, url, false);

        const objects = result.map((a: any) => new ArchiveItemViewModel(a));

        this.catalogs.set(page, objects);

        this.isLoading.set(page, false);
    }
}
