import i18n from "i18n";

function currentLanguage(): string {
    if (i18n.language === "da") {
        return "da-DK";
    } else {
        return "en-US";
    }
}

const isToday = (someDate: Date): boolean => {
    const today = new Date();
    return sameDay(today, someDate);
};

export const sameDay = (firstDate: Date, otherDate: Date): boolean => {
    return firstDate.getDate() === otherDate.getDate() && firstDate.getMonth() === otherDate.getMonth() && firstDate.getFullYear() === otherDate.getFullYear();
};

export const shortFormatDate = (date: Date) => {
    if (i18n.language === "da") {
        return `${new Intl.DateTimeFormat(currentLanguage(), {
            month: "short",
            day: "2-digit",
        }).format(date)} kl. ${new Intl.DateTimeFormat(currentLanguage(), {
            hour: "numeric",
            minute: "numeric",
        }).format(date)}`;
    } else {
        return date.toLocaleString("en-US");
    }
};

// 1. februar kl. 20.14
export const formatDate = (date: Date) => {
    if (i18n.language === "da") {
        return `${new Intl.DateTimeFormat(currentLanguage(), {
            month: "long",
            day: "numeric",
        }).format(date)} kl. ${new Intl.DateTimeFormat(currentLanguage(), {
            hour: "numeric",
            minute: "numeric",
        }).format(date)}`;
    } else {
        return date.toLocaleString("en-US");
    }
};

export function longDateFormat(date: Date): string {
    if (i18n.language === "da") {
        return `${new Intl.DateTimeFormat(currentLanguage(), {
            weekday: "long",
        }).format(date)} den ${new Intl.DateTimeFormat(currentLanguage(), {
            day: "numeric",
            month: "long",
        }).format(date)} kl ${new Intl.DateTimeFormat(currentLanguage(), {
            hour: "numeric",
            minute: "numeric",
        }).format(date)}`;
    } else {
        return date.toLocaleString("en-US");
    }
}

export function fullDateFormat(date: Date): string {
    if (i18n.language === "da") {
        return `${new Intl.DateTimeFormat(currentLanguage(), {
            day: "numeric",
            month: "long",
        }).format(date)} kl ${new Intl.DateTimeFormat(currentLanguage(), {
            hour: "numeric",
            minute: "numeric",
        }).format(date)} ${new Intl.DateTimeFormat(currentLanguage(), {
            year: "numeric",
        }).format(date)}`;
    } else {
        return date.toLocaleString("en-US");
    }
}

export function bidDeadlineDate(date: Date): string {
    if (isToday(date)) {
        return `kl. ${new Intl.DateTimeFormat(currentLanguage(), {
            hour: "numeric",
            minute: "numeric",
        }).format(date)}`;
    } else {
        return formatDate(date);
    }
}

const pascalCase = (str: string) => {
    return str.replace(/^\w/, function (chr) {
        return chr.toUpperCase();
    });
};

export function formatViewingDate(fromDate: Date, toDate: Date, upper: boolean): string {
    const day = new Intl.DateTimeFormat(currentLanguage(), {
        weekday: "long",
    }).format(fromDate);

    return `${upper ? pascalCase(day) : day} ${i18n.language === "da" ? "den" : ""} ${new Intl.DateTimeFormat(currentLanguage(), {
        day: "numeric",
        month: "short",
    }).format(fromDate)} ${i18n.language === "da" ? "kl" : "at"}  ${new Intl.DateTimeFormat(currentLanguage(), {
        hour: "numeric",
        minute: "numeric",
    }).format(fromDate)} ${i18n.language === "da" ? "til" : "to"} ${new Intl.DateTimeFormat(currentLanguage(), {
        hour: "numeric",
        minute: "numeric",
    }).format(toDate)}`;
}
