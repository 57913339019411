import { Typography } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Alerter, AlerterVariant } from 'views/alerter';
import LotDescriptionViewModel from '../viewModels/lotDescriptionViewModel';
import BidView from './bidView/bidView';

export interface LotDescriptionProps {
    catalogId: string;
    lotId: string;
    viewModel: LotDescriptionViewModel;
    className?: string;
}

const LotDescription: React.FunctionComponent<LotDescriptionProps> = observer((props) => {
    const { viewModel, catalogId, lotId } = props;

    return (
        <div className={props.className}>
            <Typography variant="body1" gutterBottom>
                {viewModel.text}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {viewModel.valuation}
            </Typography>
            {viewModel.note && <Alerter variant={AlerterVariant.info}>{viewModel.note}</Alerter>}
            {viewModel.soldNote && (
                <Alerter icon={<GavelIcon style={{ paddingTop: '6px' }} />} variant={AlerterVariant.success}>
                    {viewModel.soldNote}
                </Alerter>
            )}
            {viewModel.showBidView && (
                <BidView
                    style={{ marginTop: '1em' }}
                    onlineOnlyAuction={props.viewModel.onlineOnlyAuction}
                    catalogId={catalogId}
                    lotId={lotId}
                    catalogNumber={viewModel.catalogNumber}
                    text={viewModel.text}
                />
            )}
        </div>
    );
});

export default LotDescription;
