import { Button, TableCell, TableRow, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import ArchiveItemViewModel from '../viewModels/archiveItemViewModel';

type ArchiveCellProps = {
    archive: ArchiveItemViewModel;
};

const ArchiveCell: React.FunctionComponent<ArchiveCellProps> = (props) => {
    const { archive } = props;
    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate(`/auction/${archive.id}`);
    };

    return (
        <TableRow onClick={handleOnClick} style={{ cursor: 'pointer' }}>
            <TableCell component="th" scope="row">
                <Typography variant="body1">{archive.name}</Typography>
                <Typography variant="body2">{archive.subtitle}</Typography>
            </TableCell>
            <TableCell align="right">
                <Button onClick={handleOnClick} variant="outlined" size="small" color="primary">
                    {archive.goToCatalogText}
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default ArchiveCell;
