import { Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ActivityIndicator from 'common/views/activityIndicator';
import NoDataDialog from 'components/noDataDialog';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import Lightbox from 'react-image-lightbox';
import { useNavigate, useParams } from 'react-router-dom';
import { StoreContext } from 'stores';
import LotDetailsViewModel from './viewModels/lotDetailsViewModel';
import LotDescription from './views/lotDescription';

const useStyles = makeStyles((theme) =>
    createStyles({
        divider: {
            marginTop: 8,
            marginBottom: 8,
        },
        slider: {
            height: 400,
            maxHeight: 400,
            overflow: 'hidden',
            zIndex: 0,
        },
        sliderMedia: {
            backgroundColor: '#F5F5F5',
            objectFit: 'contain',
            width: '100%',
            height: '400px',
            position: 'absolute',
            top: '0',
            left: '0',
        },
        descriptionArea: {
            width: 1024,
            maxWidth: 1024,
        },
    })
);

type LotDetailsProps = {
    catalogId: string;
    lotId: string;
};

const LotDetails: React.FunctionComponent = observer(() => {
    const [showLightBox, setShowLightbox] = React.useState(false);
    const [photoIndex, setPhotoIndex] = React.useState(0);
    const [sliderIndex, setSliderIndex] = React.useState(0);
    const { catalogStore } = React.useContext(StoreContext);

    const params = useParams<LotDetailsProps>();

    const { catalogId = '', lotId = '' } = params;

    const catalogResult = catalogStore.catalog(catalogId);
    const lot = catalogStore.lot(catalogId, lotId);
    const styles = useStyles();
    const navigate = useNavigate();

    if (!catalogResult || !lot) {
        return <ActivityIndicator message="Indlæser lot" />;
    }

    const catalog = catalogResult.object;
    if (!catalog) {
        return <NoDataDialog title="Fejl">{catalogResult.errorMessage}</NoDataDialog>;
    }

    const viewModel = new LotDetailsViewModel(catalog, lot, catalog.auction, navigate);
    const images = viewModel.images.map((x) => x.original.path);
    return (
        <React.Fragment>
            {viewModel.images.length > 0 && (
                <AwesomeSlider selected={sliderIndex} style={showLightBox ? { pointerEvents: 'none' } : {}} className={styles.slider}>
                    {viewModel.images.map((x, index) => (
                        <div key={x.original.path}>
                            <img
                                onClick={() => {
                                    setPhotoIndex(index);
                                    setShowLightbox(true);
                                }}
                                alt="billede af lot"
                                className={styles.sliderMedia}
                                src={x.original.path}
                            />
                        </div>
                    ))}
                </AwesomeSlider>
            )}
            {viewModel.images.length > 1 && (
                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '1em' }}>
                    {viewModel.images.map((image, index) => (
                        <img key={image.thumbnail.path} onClick={() => setSliderIndex(index)} style={{ height: '50px', margin: '5px' }} src={image.thumbnail.path} alt="thumbnail" />
                    ))}
                </div>
            )}
            <div style={{ margin: '1em' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <div className={styles.descriptionArea}>
                        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                            {viewModel.title}
                        </Typography>
                        {viewModel.subtitle}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <LotDescription className={styles.descriptionArea} viewModel={viewModel.lotDescriptionViewModel} catalogId={catalogId} lotId={lotId} />
                </div>
            </div>
            {showLightBox && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setShowLightbox(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                />
            )}
        </React.Fragment>
    );
});

export default LotDetails;
