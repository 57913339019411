import * as React from 'react';
import currency_formatter from 'common/utilities/currency_formatter';
import { observer } from 'mobx-react-lite';
import { Typography } from '@mui/material';

export interface FirstBidForUserProps {
	currentBid: number;
	nextMinimumBid: number;
}

const FirstBidForUser: React.FunctionComponent<FirstBidForUserProps> = observer((props) => {
	return (
		<React.Fragment>
			<Typography gutterBottom variant="h5" component="h2">
				Bud
			</Typography>
			<Typography variant="body1" color="textPrimary" component="p">
				Det aktuelle bud er {currency_formatter(props.currentBid)}
				<br /> Næste overbud vil være {currency_formatter(props.nextMinimumBid)}
			</Typography>
		</React.Fragment>
	);
});

export default FirstBidForUser;
