import React from 'react';
import Countdown from 'react-countdown';

interface BidCountdownProps {
    biddingDeadline: Date;
    onDone?: () => void;
}

const BidCountdown: React.FunctionComponent<BidCountdownProps> = ({ biddingDeadline, onDone }) => {
    const [isDone, setIsDone] = React.useState(false);
    return (
        <Countdown
            date={biddingDeadline}
            renderer={(x) => {
                let str = '';
                if (x.days > 0) {
                    str += `${x.days} dag${x.days > 1 ? 'e' : ''} `;
                    return str;
                }

                if (x.hours > 0) {
                    str += `${x.hours} time${x.hours > 1 ? 'r' : ''} `;
                    return str;
                }

                if (x.minutes > 0) {
                    str += `${x.minutes} minut${x.minutes > 1 ? 'ter' : ''} `;
                }

                str += `${x.seconds} sekunder`;

                if (x.seconds <= 0 && onDone && !isDone) {
                    onDone();
                    setIsDone(true);
                }

                return <React.Fragment>{str.trim()}</React.Fragment>;
            }}
        />
    );
};

export default BidCountdown;
