import { IAuctionDto } from './../dtos/IAuctionDto';
import AuctionViewing from './auctionViewingBackend';

export default class AuctionDto {
    public biddingDeadline: Date | undefined;
    public canBid: boolean;
    public completed: boolean;
    public date: Date | undefined;
    public id: string;
    public title: string;
    public catalogId?: string;
    public viewings: AuctionViewing[];
    public note: string;
    public onlineOnlyAuction: boolean;
    public archived: boolean;

    constructor(object: IAuctionDto) {
        this.biddingDeadline = object.biddingDeadline ? new Date(object.biddingDeadline) : undefined;
        this.id = object.id;
        this.canBid = object.canBid;
        this.completed = object.completed;
        this.date = object.date ? new Date(object.date) : undefined;
        this.title = object.title;
        this.catalogId = object.catalogId;
        this.viewings = object.viewings.map((x) => new AuctionViewing(x));
        this.note = object.note;
        this.onlineOnlyAuction = object.onlineOnlyAuction;
        this.archived = object.archived;
    }
}
