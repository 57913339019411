import { LogonResult } from './LogonResult';
import { CognitoUser } from '@aws-amplify/auth';

export class VerbatimLogonResult {
	public status: LogonResult;
	public message: string;
	public user: CognitoUser | any | undefined;

	constructor(status: LogonResult, message: string, user: CognitoUser | any | undefined = undefined) {
		this.status = status;
		this.message = message;
		this.user = user;
	}
}
