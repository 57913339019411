import CatalogModel from 'models/backend/catalogModel';
import SectionModel from 'models/sectionModel';
import CatalogSectionLotCellViewModel from 'pages/catalog_section/viewModels/catalogSectionLotCellViewModel';
import React from 'react';
import { Link } from 'react-router-dom';
import { longDateFormat } from 'utilities/dateFormatter';

export default class CatalogViewModel {
    public isLoading: boolean;
    public title: string;
    public subtitle: React.ReactNode;
    public lots: CatalogSectionLotCellViewModel[] = [];

    constructor(catalogModel?: CatalogModel, sectionModel?: SectionModel) {
        this.isLoading = !catalogModel || !sectionModel;
        if (catalogModel === undefined || sectionModel === undefined) {
            this.isLoading = true;
            this.title = '';
            this.subtitle = <div />;
        } else {
            this.isLoading = false;

            this.title = sectionModel.name;
            if (catalogModel.auction.archived) {
                this.subtitle = (
                    <React.Fragment>
                        Bemærk at <Link to={`/auction/${catalogModel.auction.id}`}>{catalogModel.auction.title}</Link> allerede har været afholdt og er nu en del af vores auktions arkiv.
                    </React.Fragment>
                );
            } else {
                this.subtitle = (
                    <React.Fragment>
                        Auktionen <Link to={`/auction/${catalogModel.auction.id}`}>{catalogModel.auction.title}</Link> starter i auktionshuset {longDateFormat(catalogModel.auction.date!)}. <br />
                        Der kan lægges kommissionsbud på hjemmesiden indtil {longDateFormat(catalogModel.auction.biddingDeadline!)}.
                    </React.Fragment>
                );
            }

            this.lots = sectionModel.lots
                .sort((x, y) => x.catalogNumber - y.catalogNumber)
                .map((x) => new CatalogSectionLotCellViewModel(catalogModel, catalogModel.auction.archived, catalogModel.auction.onlineOnlyAuction, x));
        }
    }
}
