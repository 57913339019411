import { Grid } from '@mui/material';
import React from 'react';
import CatalogSectionLotCellViewModel from '../viewModels/catalogSectionLotCellViewModel';
import LotCard from './lotCard';
import Pager from './pager';

export interface LotsProps {
    page: number;
    setPage: (page: number) => void;
    itemsPerPage: number;
    catalogId: string;
    lots: CatalogSectionLotCellViewModel[];
}

const Lots: React.FunctionComponent<LotsProps> = ({ page, setPage, itemsPerPage, catalogId, lots }) => {
    // React.useEffect(() => {
    //     window.scrollTo(0, 0);
    // });

    return (
        <div style={{ padding: '1rem' }}>
            <Pager page={page} setPage={setPage} totalCount={lots.length} itemsPerPage={itemsPerPage} />
            <Grid container spacing={3}>
                {lots.slice(page * itemsPerPage, (page + 1) * itemsPerPage).map((x) => (
                    <Grid key={x.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <LotCard catalogId={catalogId} lotId={x.id} model={x} initialBidStatus={undefined} />
                    </Grid>
                ))}
            </Grid>
            <Pager page={page} setPage={setPage} totalCount={lots.length} itemsPerPage={itemsPerPage} />
        </div>
    );
};

export default Lots;
