import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ActivityIndicator from 'common/views/activityIndicator';
import Title from 'common/views/title';
import DefaultMargin from 'components/DefaultMargin';
import NetworkErrorView from 'components/networkErrorView';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useParams } from 'react-router';
import { StoreContext } from 'stores';
import { MyAuctionPurchasesViewModel } from './viewModels/MyAuctionPurchasesViewModel';

type MyAuctionPurchasesProps = {
    auctionId: string;
};

const MyAuctionPurchases: React.FunctionComponent = observer(() => {
    const { purchaseStore } = React.useContext(StoreContext);
    const params = useParams<MyAuctionPurchasesProps>();

    const { auctionId = '' } = params;

    const purchases = purchaseStore.getAuctionPurchases(auctionId);
    if (purchases.loading) {
        return <ActivityIndicator message="Finder dine køb" />;
    }

    if (purchases.errorMessage) {
        return <NetworkErrorView errorMessage={purchases.errorMessage} />;
    }

    if (!purchases.data) {
        return <NetworkErrorView errorMessage={'Har ikke modtaget noget data'} />;
    }

    const auction = purchases.data;
    const viewModel = new MyAuctionPurchasesViewModel(auction);

    return (
        <DefaultMargin>
            <Title>{viewModel.title}</Title>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Text</TableCell>
                            <TableCell align="right">Pris</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {viewModel.cellViewModels.map((cell) => (
                            <TableRow style={{ cursor: 'pointer' }} key={cell.id}>
                                <TableCell>{cell.catalogNumber}</TableCell>
                                <TableCell>{cell.text}</TableCell>
                                <TableCell align="right">{cell.price}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </DefaultMargin>
    );
});

export default MyAuctionPurchases;
