import CatalogModel from 'models/backend/catalogModel';
import CatalogSectionModel from 'models/backend/catalogSectionBackendModel';
import LotModel from 'models/backend/lotBackendModel';

export default function lotsForSection(
    catalogModel: CatalogModel,
    sectionId: string
): {
    valid: boolean;
    object?: { section: CatalogSectionModel; lots: LotModel[] };
} {
    // get the requested section
    const section = catalogModel.sections.find((x) => x.id === sectionId);

    if (!section) {
        console.debug('Failed to find section with the provided id');
        return { valid: false };
    }

    // get all sections that has lots that occur after the requested
    const followingSections = catalogModel.sections.filter((x) => x.fromLot > section.fromLot);

    // was there any or is the requested the last one?
    if (followingSections.length > 0) {
        const nextSection = followingSections.reduce((next, curr) => (next.fromLot < curr.fromLot ? next : curr));

        const lots = catalogModel.lots.filter((x) => x.catalogNumber >= section.fromLot && x.catalogNumber < nextSection.fromLot);

        return { valid: true, object: { section, lots } };
    } else {
        const lots = catalogModel.lots.filter((x) => x.catalogNumber >= section.fromLot);

        return { valid: true, object: { section, lots } };
    }
}
