import * as React from 'react';
import { Typography } from '@mui/material';
import currency_formatter from 'common/utilities/currency_formatter';

export interface LosingBidProps {
    myMaxBid: number;
    highestBid: number;
    nextMinimumBid: number;
}

const LosingBid: React.FunctionComponent<LosingBidProps> = (props) => {
    return (
        <React.Fragment>
            <Typography gutterBottom variant="h5" component="h2">
                Du har budt på dette nummer men er ikke højestbydende i øjeblikket
            </Typography>
            <Typography variant="body1" color="textPrimary" component="p">
                Dit max bud var {currency_formatter(props.myMaxBid)} og det nuværende bud er på {currency_formatter(props.highestBid)}
                <br /> Næste overbud vil være {currency_formatter(props.nextMinimumBid)}
            </Typography>
        </React.Fragment>
    );
};

export default LosingBid;
