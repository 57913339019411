import { ICatalogDto } from 'models/dtos/ICatalogDto';
import AuctionDto from './AuctionDto';
import CatalogSectionModel from './catalogSectionBackendModel';
import LotModel from './lotBackendModel';

export default class CatalogModel {
    public readonly id: string;
    public readonly auction: AuctionDto;
    public readonly sections: CatalogSectionModel[];
    public lots: LotModel[];

    constructor(catalog: ICatalogDto) {
        this.auction = new AuctionDto(catalog.auction);
        this.id = catalog.catalogId ?? catalog.id ?? 'ukendt id';
        this.sections = catalog.sections.map((x: any) => {
            return new CatalogSectionModel(x);
        });
        this.lots = catalog.lots.map((x: any) => {
            return new LotModel(x);
        });
    }
}
