import React from 'react';
import { Typography } from '@mui/material';
import i18n from 'i18n';

export interface SwitchLocaleProps {
    compact: Boolean;
}

const SwitchLocale: React.FunctionComponent<SwitchLocaleProps> = (props) => {
    const currentLanguage = i18n.language;
    let otherLanguageCode = 'da';
    let otherLanguageIcon = '🇩🇰';
    let otherLanguageAriaLabel = 'skift til dansk';
    if (currentLanguage === 'da') {
        otherLanguageCode = 'en';
        otherLanguageIcon = '🇬🇧';
        otherLanguageAriaLabel = 'switch to english';
    }

    if (props.compact) {
        return (
            <span style={{ cursor: 'pointer' }} onClick={() => i18n.changeLanguage(otherLanguageCode)} role="img" aria-label={otherLanguageAriaLabel}>
                <Typography variant="caption" style={{ color: 'white' }}>
                    {otherLanguageIcon}
                </Typography>
            </span>
        );
    } else {
        return (
            <span style={{ cursor: 'pointer' }} onClick={() => i18n.changeLanguage(otherLanguageCode)} role="img" aria-label={otherLanguageAriaLabel}>
                <Typography variant="caption" style={{ color: 'white' }}>
                    {otherLanguageIcon}
                </Typography>
            </span>
        );
    }
};

export default SwitchLocale;
