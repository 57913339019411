import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Typography } from '@mui/material';

export enum AlerterVariant {
    error,
    success,
    info,
    warning,
}

export interface AlerterProps {
    children?: React.ReactNode;
    variant: AlerterVariant;
    icon?: JSX.Element;
    style?: React.CSSProperties;
}

export const Alerter: React.FunctionComponent<AlerterProps> = (props) => {
    let backgroundColor: string;
    let textColor: string;
    let borderColor: string;
    switch (props.variant) {
        case AlerterVariant.error:
            backgroundColor = '#FEEFEF';
            textColor = '#DA1414';
            borderColor = '#F48989';
            break;
        case AlerterVariant.success:
            backgroundColor = '#EDF9F0';
            textColor = '#287D3C';
            borderColor = '#5ACA75';
            break;
        case AlerterVariant.info:
            textColor = '#2E5AAC';
            backgroundColor = '#EEF2FA';
            borderColor = '#89A7E0';
            break;
        case AlerterVariant.warning:
            backgroundColor = '#FFF4EC';
            textColor = '#B95000';
            borderColor = '#FF8F39';
            break;
    }

    const iconMargin = '4px';

    return (
        <div style={{ backgroundColor, borderRadius: '8px', border: `1px solid ${borderColor}`, ...props.style }}>
            <div style={{ margin: '0.5rem', display: 'flex', flexWrap: 'nowrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                {!props.icon && <InfoIcon style={{ color: textColor, marginRight: iconMargin }} />}
                {<div style={{ color: textColor, marginRight: iconMargin }}>{props.icon}</div>}
                <Typography variant="body2" style={{ color: textColor }}>
                    {props.children}
                </Typography>
            </div>
        </div>
    );
};
