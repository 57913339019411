import MenuIcon from '@mui/icons-material/Menu';
import { Divider, Drawer, List, ListItem, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from 'stores';

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    link: {
        margin: theme.spacing(1, 1.5),
        color: 'white',
    },
}));

export interface MobileLinksProps {
    className: string;
}

const MobileLinks: React.FunctionComponent<MobileLinksProps> = observer((props) => {
    const navigate = useNavigate();
    const styles = useStyles();
    const [showDrawer, setShowDrawer] = React.useState(false);
    const { userStore } = React.useContext(StoreContext);
    const { t } = useTranslation();

    const selectedPage = (url: string) => {
        navigate(url);
        setShowDrawer(false);
    };

    return (
        <React.Fragment>
            <nav className={props.className}>
                <IconButton edge="start" onClick={() => setShowDrawer(!showDrawer)} color="inherit" aria-label="menu" size="large">
                    <MenuIcon style={{ color: 'white' }} />
                </IconButton>
            </nav>
            <Drawer anchor="left" open={showDrawer} onClose={() => setShowDrawer(false)}>
                <div className={styles.list}>
                    <List>
                        <ListItem onClick={() => selectedPage('/auctions')} button>
                            <ListItemText primary={t('menu.auctions')} />
                        </ListItem>
                        <ListItem onClick={() => selectedPage('/buysell')} button>
                            <ListItemText primary={t('menu.buy_and_sell')} />
                        </ListItem>
                        <ListItem onClick={() => selectedPage('/valuations')} button>
                            <ListItemText primary={t('menu.valuations')} />
                        </ListItem>
                        <ListItem onClick={() => selectedPage('/archive')} button>
                            <ListItemText primary={t('menu.archive')} />
                        </ListItem>
                        <ListItem onClick={() => selectedPage('/about')} button>
                            <ListItemText primary={t('menu.about')} />
                        </ListItem>
                    </List>
                    <Divider />
                    {userStore.isAuthenticated && (
                        <List>
                            <ListItem onClick={() => selectedPage('/my_profile')} button>
                                <ListItemText primary={t('menu.my_profile')} />
                            </ListItem>
                            <ListItem onClick={() => selectedPage('/my_bids')} button>
                                <ListItemText primary={t('menu.current_bids')} />
                            </ListItem>
                            <ListItem onClick={() => selectedPage('/my_purchases')} button>
                                <ListItemText primary={t('menu.my_purchases')} />
                            </ListItem>

                            <ListItem
                                onClick={() => {
                                    userStore.logout();
                                    navigate('/');
                                }}
                                button
                            >
                                <ListItemText primary={t('generic.logout')} />
                            </ListItem>
                        </List>
                    )}
                    {!userStore.isAuthenticated && (
                        <List>
                            <ListItem onClick={() => selectedPage('/logon')} button>
                                <ListItemText primary={t('generic.logon')} />
                            </ListItem>
                        </List>
                    )}
                </div>
            </Drawer>
        </React.Fragment>
    );
});

export default MobileLinks;
