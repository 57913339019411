import { ILotDto } from '../dtos/ILotDto';
import ImageModel from './imageBackendModel';

export default class LotModel {
    public catalogNumber: number;
    public copyDanRestricted: boolean;
    public creator: string | undefined;
    public didSell: boolean | undefined;
    public fullVAT: boolean;
    public id: string;
    public images: ImageModel[];
    public minimumBid: number;
    public lotNote: string | undefined;
    public price: number | undefined;
    // list of lot ids for other lots in the same lot group
    public soldWithOtherLots: string[];
    public text: string;
    public valuation?: number;

    constructor(object: ILotDto) {
        this.catalogNumber = object.catalogNumber;
        this.copyDanRestricted = object.copyDanRestricted;
        this.creator = object.creator;
        this.didSell = object.saleInfo !== undefined;
        this.fullVAT = object.fullVAT;
        this.id = object.id;
        if (object.images) {
            this.images = object.images
                .map((x: any) => {
                    return new ImageModel(x);
                })
                .sort((a, b) => a.order - b.order);
        } else {
            this.images = [];
        }
        this.minimumBid = object.minimumBid;
        this.lotNote = object.note;
        this.price = object.saleInfo?.price;
        this.soldWithOtherLots = object.saleInfo ? object.saleInfo.grouped : [];
        this.text = object.text;
        this.valuation = object.valuation;
    }
}
