import { Container, Typography } from '@mui/material';
import ActivityIndicator from 'common/views/activityIndicator';
import Title from 'common/views/title';
import DefaultMargin from 'components/DefaultMargin';
import NoDataDialog from 'components/noDataDialog';
import { observer } from 'mobx-react-lite';
import AuctionHeader from 'pages/auctionHeader';
import Catalog from 'pages/catalog';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { StoreContext } from 'stores';
import { Alerter, AlerterVariant } from 'views/alerter';

type AuctionProps = {
    auctionId: string;
};

const Auction: React.FunctionComponent = observer(() => {
    const { auctionStore } = React.useContext(StoreContext);
    const { t } = useTranslation();
    const params = useParams<AuctionProps>();

    const auctionResult = auctionStore.auction(params.auctionId!);
    if (!auctionResult) {
        return <ActivityIndicator message="Indlæser auktion" />;
    }

    const auction = auctionResult.object;
    if (!auction) {
        return <NoDataDialog title="Fejl">{auctionResult.errorMessage}</NoDataDialog>;
    }

    return (
        <DefaultMargin>
            <Container maxWidth="md">
                <Typography variant="overline">{auction.onlineOnlyAuction ? t('online_auction') : t('traditional_auction')}</Typography>

                <Title>{auction.title}</Title>

                <AuctionHeader auction={auction} />

                {auction.note && (
                    <Alerter variant={AlerterVariant.info} style={{ marginTop: '1rem' }}>
                        {auction.note}
                    </Alerter>
                )}

                <div style={{ marginTop: '1rem' }}>
                    {auction.catalogId && <Catalog catalogId={auction.catalogId} />}
                    {!auction.catalogId && <Alerter variant={AlerterVariant.info}>{t('auction.no_catalog')}</Alerter>}
                </div>
            </Container>
        </DefaultMargin>
    );
});

export default Auction;
