import { Card, CardContent, Typography } from '@mui/material';
import ActivityIndicator from 'common/views/activityIndicator';
import Title from 'common/views/title';
import DefaultMargin from 'components/DefaultMargin';
import NetworkErrorView from 'components/networkErrorView';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { fullDateFormat } from 'utilities/dateFormatter';
import useRequest from 'utilities/useRequest';
import MyBidsList from './components/myBidsList';
import { IActiveUserBidsDto } from './Dtos/IActiveUserBidsDto';

export interface MyBidsProps {}

const MyBids: React.FunctionComponent<MyBidsProps> = observer(() => {
    const data = useRequest(undefined, 'profile/activeBids', true);
    const { t } = useTranslation();

    if (data.loading) {
        return <ActivityIndicator message="Finder dine bud" />;
    }

    if (data.errorMessage) {
        return <NetworkErrorView errorMessage={data.errorMessage} />;
    }

    if (!data.data) {
        return <NetworkErrorView errorMessage={'Har ikke modtaget noget data'} />;
    }

    const auctions = data.data as IActiveUserBidsDto;

    return (
        <DefaultMargin>
            <Title>{t('my_bids.title')}</Title>
            {auctions.auctions.map((auction, index) => (
                <Card key={auction.auction.id} style={index > 0 ? { marginTop: '1em' } : {}}>
                    <CardContent>
                        <Typography variant="h6">{auction.auction.title}</Typography>
                        <Typography variant="subtitle2">{fullDateFormat(new Date(auction.auction.date!))}</Typography>
                        <MyBidsList auction={auction} />
                    </CardContent>
                </Card>
            ))}

            {auctions.auctions.length === 0 && <Typography variant="body1">Vi har ingen registrerede bud for dig</Typography>}
        </DefaultMargin>
    );
});

export default MyBids;
