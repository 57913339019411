import React, { ReactNode } from 'react';
import { Typography, Divider } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme) =>
	createStyles({
		divider: {
			marginBottom: theme.spacing(2)
		}
	})
);

export interface TitleProps {
	children: ReactNode;
}

const Title: React.FunctionComponent<TitleProps> = ({ children }) => {
	const styles = useStyles({});

	return (
		<React.Fragment>
			<Typography gutterBottom variant="h5">
				{children}
			</Typography>
			<Divider className={styles.divider} />
		</React.Fragment>
	);
};

export default Title;
