import React from 'react';
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(2, 2),
        },
    })
);

interface DefaultMarginProps {
    children: React.ReactNode;
}

const DefaultMargin: React.FunctionComponent<DefaultMarginProps> = (props) => {
    const styles = useStyles(props);
    return <div className={styles.root}>{props.children}</div>;
};

export default DefaultMargin;
