import _ from 'lodash';
import defaultSettings from './default.json';
import dev from './dev.json';
import localprod from './localprod.json';
import prod from './production.json';

const forceLocalProd = true;

function settings() {
    if (forceLocalProd && "prod" !== 'prod') {
        return _.merge(defaultSettings, localprod);
    }

    switch ("prod") {
        case 'dev':
            return _.merge(defaultSettings, dev);
        case 'prod':
            return _.merge(defaultSettings, prod);
        default:
            break;
    }

    return defaultSettings;
}

export default settings;
