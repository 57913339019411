import { Button, Container, Divider, Paper, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AxiosError, AxiosResponse } from 'axios';
import TextInput, { InputTypes } from 'common/form/textInput';
import { useForm } from 'common/form/useForm';
import { post } from 'common/network';
import DefaultMargin from 'components/DefaultMargin';
import PasswordRules from 'pages/forgot_password/components/passwordRules';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import config from 'utilities/config';

const useStyles = makeStyles((theme) =>
    createStyles({
        input: {
            width: '100%',
        },
    })
);

interface CreateUserResponse {
    cognitoId: string;
}

const CreateUserPage: React.FunctionComponent = () => {
    const styles = useStyles();
    const [error, setError] = React.useState('');
    const [passwordValid, setPasswordValid] = React.useState(false);
    const [missingFields, setMissingFields] = React.useState<string[]>([]);
    const [duplicateEmail, setDuplicateEmail] = React.useState(false);
    const [validEmail, setValidEmail] = React.useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { inputs, handleInputChange, handleSubmit } = useForm(
        async (changed: any) => {
            delete changed.verifyPassword;
            try {
                const createdUserResponseData: AxiosResponse<CreateUserResponse> = await post(config().services.user, 'bidder', changed, false);
                const createUserResponse = createdUserResponseData.data;
                navigate('/create_user/confirm_code', { state: { cognitoId: createUserResponse.cognitoId, email: changed.email } });
            } catch (ex: any) {
                setDuplicateEmail(false);
                setError('');
                setMissingFields([]);
                console.log(`ex`, ex);

                if (ex.isAxiosError) {
                    const axiosError = ex as AxiosError;
                    if (axiosError.response?.status === 400) {
                        const errorMessage = axiosError.response?.data;
                        if (errorMessage && errorMessage === 'invalid_phone_number_format') {
                            setError('Telefon nummer skal være med landekode foran og ingen mellemrum eller specialtegn');
                        }
                    } else if (axiosError.response && axiosError.response.data && axiosError.response.data.code === 'UsernameExistsException') {
                        setDuplicateEmail(true);
                        setError(t('create_user.email_already_in_use_error'));
                    } else {
                        if (axiosError.message) {
                            setError(axiosError.message);
                        } else if (axiosError.response) {
                            setError(axiosError.response.data);
                        } else {
                            setError('Technical Error');
                        }
                    }
                }
            }
            return false;
        },
        { country: 'Denmark', phoneNumber: '+45' }
    );

    const validate = (email: string): boolean => {
        const expression =
            // eslint-disable-next-line
            /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

        return expression.test(String(email).toLowerCase());
    };

    return (
        <DefaultMargin>
            <Container maxWidth="xs">
                <Paper style={{ padding: '1em' }}>
                    <Typography gutterBottom variant="h5">
                        {t('create_user.title')}
                    </Typography>
                    <Divider />
                    <TextInput
                        className={styles.input}
                        autoFocus
                        fieldName="email"
                        object={inputs}
                        label={t('generic.email')}
                        onChange={(e) => {
                            const email = e.currentTarget.value;
                            setValidEmail(validate(email));
                            setDuplicateEmail(false);
                            handleInputChange(e);
                        }}
                        errorMessage={!validEmail ? t('create_user.invalid_email_error') : duplicateEmail ? t('create_user.duplicate_email_error') : ''}
                    />
                    <TextInput
                        className={styles.input}
                        fieldName="password"
                        object={inputs}
                        label={t('create_user.password')}
                        type={InputTypes.password}
                        onChange={handleInputChange}
                        missingFields={missingFields}
                    />
                    <TextInput
                        className={styles.input}
                        fieldName="verifyPassword"
                        object={inputs}
                        label={t('create_user.repeat_password')}
                        type={InputTypes.password}
                        onChange={handleInputChange}
                        missingFields={missingFields}
                    />

                    <PasswordRules passwordValid={setPasswordValid} password={inputs.password} verifyPassword={inputs.verifyPassword} />

                    <TextInput className={styles.input} fieldName="firstName" object={inputs} label={t('create_user.firstname')} onChange={handleInputChange} missingFields={missingFields} />
                    <TextInput className={styles.input} fieldName="lastName" object={inputs} label={t('create_user.lastname')} onChange={handleInputChange} missingFields={missingFields} />
                    <TextInput className={styles.input} fieldName="companyName" object={inputs} label={t('create_user.company_name')} onChange={handleInputChange} missingFields={missingFields} />
                    <TextInput className={styles.input} fieldName="phoneNumber" object={inputs} label={t('create_user.phone_number')} onChange={handleInputChange} missingFields={missingFields} />
                    <TextInput className={styles.input} fieldName="streetName" object={inputs} label={t('create_user.address')} onChange={handleInputChange} missingFields={missingFields} />
                    <TextInput className={styles.input} fieldName="postalCode" object={inputs} label={t('create_user.postal_code')} onChange={handleInputChange} missingFields={missingFields} />
                    <TextInput className={styles.input} fieldName="city" object={inputs} label={t('create_user.city')} onChange={handleInputChange} missingFields={missingFields} />
                    <TextInput className={styles.input} fieldName="province" object={inputs} label={t('create_user.province')} onChange={handleInputChange} missingFields={missingFields} />
                    <TextInput className={styles.input} fieldName="country" object={inputs} label={t('create_user.country')} onChange={handleInputChange} missingFields={missingFields} />

                    {error && (
                        <Typography style={{ paddingTop: '1em' }} variant="body1" color="error">
                            {error}
                        </Typography>
                    )}

                    <Button onClick={handleSubmit} style={{ marginTop: '2em' }} variant="contained" color="primary" disabled={!passwordValid || !validEmail || duplicateEmail}>
                        {t('create_user.action_button')}
                    </Button>

                    {duplicateEmail && (
                        <Button
                            onClick={() => {
                                navigate('/logon/forgot_password', { state: { username: inputs.email } });
                            }}
                            style={{ float: 'right', marginTop: '2em' }}
                            variant="contained"
                            color="secondary"
                        >
                            {t('logon.forgotten_password')}
                        </Button>
                    )}
                </Paper>
            </Container>
        </DefaultMargin>
    );
};

export default CreateUserPage;
