import { Paper, Typography } from '@mui/material';
import React from 'react';
import config from 'utilities/config';

const AboutPage: React.FunctionComponent = () => {
    return (
        <React.Fragment>
            <iframe
                title="Stationsvej 76, 7500 Holstebro"
                height="450"
                frameBorder={0}
                style={{ backgroundColor: 'gray', border: 0, width: '100%' }}
                src={`https://www.google.com/maps/embed/v1/place?q=place_id:ChIJOzHBW3IzSkYRzWmZY4ohOWA&key=${config().googlemaps}`}
                allowFullScreen
            ></iframe>
            <div style={{ marginTop: '1em', display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Paper style={{ maxWidth: 1024, width: 1024, marginLeft: '1em', marginRight: '1em' }}>
                    <div style={{ backgroundColor: 'black', padding: '1em' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <img src={require('assets/logo.png')} alt="Logo" height="41" width="23" />
                        </div>
                        <Typography variant="h5" style={{ textAlign: 'center', width: '100%', color: 'white' }}>
                            Holstebro Auktioner
                        </Typography>
                    </div>
                    <div style={{ padding: '1em' }}>
                        <Typography variant="body1">
                            <p>
                                <b>Adresse:</b>
                                <br /> Stationsvej 76
                                <br /> 7500 Holstebro
                                <br /> Tlf: 97 40 11 11
                                <br /> Mail: mail@ho-auk.dk
                                <br />
                            </p>
                            <p>
                                <b>Kontorets åbningstider:</b>
                                <br /> Mandag: 9.00-16.00
                                <br /> Tirsdag: 9.00-16.00
                                <br /> Onsdag: Lukket
                                <br /> Torsdag: 9.00-16.00 (i auktionsuger dog 9.00-18.00)
                                <br /> Fredag: 9.00-13.30
                            </p>
                            <p>
                                Holstebro Auktioner er grundlagt i 1985 af den nuværende indehaver Hans Jørgen Eriksen, der er beskikket auktionsleder i Holstebro og Ringkøbing retskredse, cand.jur og
                                tidligere advokat. Vi råder i dag over flere haller, et stort receptionsområde med cafe og lyse udstillingslokaler. Vi har store lagerfaciliteter og stor
                                parkeringsplads lige ved døren.{' '}
                                <p>
                                    Der er gennem årene afholdt hundredevis af auktioner med salg fra private, dødsboer, konkursboer, SKAT, Politi og advokater. Auktionshuset har købere over hele
                                    Verden der følger vore auktioner.
                                </p>
                                <p>
                                    Gennem årene har vi afholdt mange spændende auktioner, bl.a. de mest omtalte kan nævnes konkursboet efter Alfred Christensens Musikforretning, salg af Karen Blixen
                                    og Kaj Munks biler, COBRA-auktion, flere auktioner med Skagens Kunst. Gennem årene er det også blevet til flere salg til fremtrædende Museer.
                                </p>{' '}
                                Auktionshusets personale har mange års erfaring i vurderinger og salg på auktion, ligesom de ofte er benyttet i forbindelse med TV-udsendelser og avisartikler.
                            </p>
                        </Typography>
                    </div>
                </Paper>
            </div>
        </React.Fragment>
    );
};

export default AboutPage;
