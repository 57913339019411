import * as React from 'react';
import { FormControl, InputLabel, Input } from '@mui/material';

interface PasswordInputProps {
	autoFocus?: boolean;
	style?: React.CSSProperties;
	customClassName?: string;
	label: string;
	fieldName: string;
	object: any;
	onChange: (event: any) => void;
}

const PasswordInput = (props: PasswordInputProps) => {
	return (
		<FormControl className={props.customClassName} style={props.style} margin="dense">
			<InputLabel htmlFor={`${props.fieldName}-input`}>{props.label}</InputLabel>
			<Input
				autoFocus={props.autoFocus ? props.autoFocus : false}
				id={`${props.fieldName}-input`}
				type="password"
				name={props.fieldName}
				value={props.object[props.fieldName] ? props.object[props.fieldName] : ''}
				onChange={props.onChange}
			/>
		</FormControl>
	);
};

export default PasswordInput;
