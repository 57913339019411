import ActivityIndicator from 'common/views/activityIndicator';
import Title from 'common/views/title';
import DefaultMargin from 'components/DefaultMargin';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { StoreContext } from 'stores';
import parseQuery from 'utilities/queryParse';
import CatalogArchiveTable from './views/catalogArchiveTable';

const ArchiveView: React.FunctionComponent = observer(() => {
    const { archiveStore } = React.useContext(StoreContext);
    const [page, setPage] = React.useState(0);
    let items = archiveStore.get_page(page);
    const location = useLocation();

    useEffect(() => {
        const queryParams = parseQuery(location.search);
        let pageIndex = 0;
        let queryPage = queryParams.get('page');
        if (queryPage) {
            pageIndex = parseInt(queryPage);
        }

        setPage(pageIndex);
        return () => {};
    }, [location]);

    if (items === undefined) {
        return <ActivityIndicator />;
    }

    return (
        <DefaultMargin>
            <Title>Katalog Arkiv</Title>
            <CatalogArchiveTable items={items} page={page} setPage={setPage} />
        </DefaultMargin>
    );
});

export default ArchiveView;
