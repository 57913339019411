import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import AuctionCellViewModel from '../viewModels/auctionCellViewModel';

export interface AuctionCellProps {
    viewModel: AuctionCellViewModel;
}

const AuctionCell: React.FunctionComponent<AuctionCellProps> = ({ viewModel }) => {
    const navigate = useNavigate();

    const onClick = () => navigate(viewModel.auctionUrl);

    return (
        <Card style={{ cursor: 'pointer' }} onClick={onClick}>
            <CardContent>
                <Typography variant="h6">{viewModel.title}</Typography>
                <Typography variant="body1">{viewModel.subtitle}</Typography>
                {viewModel.note.length > 0 && <Typography variant="subtitle1">{viewModel.note}</Typography>}
            </CardContent>
            {viewModel.auctionUrl && (
                <CardActions>
                    <Button variant="outlined" onClick={onClick} size="small" color="primary">
                        Detaljer
                    </Button>
                </CardActions>
            )}
        </Card>
    );
};

export default AuctionCell;
