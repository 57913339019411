import * as React from 'react';
import ReactModal from 'react-modal';
import { StoreContext } from 'stores';
import { observer } from 'mobx-react-lite';
import { Typography, Divider, Button, Checkbox, FormControlLabel } from '@mui/material';
import currency_formatter from 'common/utilities/currency_formatter';

const customStyles ={
	content : {
		top                   : '50%',
		left                  : '50%',
		right                 : 'auto',
		bottom                : 'auto',
		marginRight           : '-50%',
		transform             : 'translate(-50%, -50%)'
	}
  };

export interface QuickBidModalProps {}

const QuickBidModal: React.FunctionComponent<QuickBidModalProps> = observer((props) => {
	const { biddingStore } = React.useContext(StoreContext);
	const [receiveMessage, setReceiveMessage] = React.useState(true);

	if (!biddingStore.currentBid) {
		return <div />;
	}

	const bid = biddingStore.currentBid.bid;

	return (
		<ReactModal isOpen={biddingStore.isQuickBidding} style={customStyles}>
			<Typography gutterBottom variant="h5">
				Bekræft Bud
			</Typography>
			<Divider />
			<br />
			<Typography variant="body1" gutterBottom>
				Du er ved at lægge et bud på {currency_formatter(bid)}
			</Typography>

			<FormControlLabel
				control={<Checkbox checked={receiveMessage} onChange={() => setReceiveMessage(!receiveMessage)} />}
				label="Vil du modtage en besked ved overbud?"
			/>
			<Typography variant="body2">Ved at lægge budet bekræfter du at have læst vores købskonditioner.</Typography>

			<div style={{ paddingTop: '1em' }}>
				<Button
					style={{ marginRight: '1em' }}
					size="large"
					variant="contained"
					color="primary"
					onClick={() => {
						biddingStore.confirmBid(biddingStore.currentBid!, receiveMessage);
					}}
				>
					Bekræft
				</Button>
				<Button
					size="large"
					variant="contained"
					color="secondary"
					onClick={() => {
						biddingStore.endBidding();
					}}
				>
					Fortryd
				</Button>
			</div>
		</ReactModal>
	);
});

export default QuickBidModal;
