export enum LogonResult {
	success,
	failure,
	wrongPassword,
	newPassword,
	passwordResetRequired,
	userDoesNotExist,
	requiresConfirmation,
	acceptTerms
}
