import React from 'react';
import { TableRow, TableCell, Typography, Icon } from '@mui/material';
import KeyboardIconRight from '@mui/icons-material/KeyboardArrowRight';

export interface SectionCellProps {
    title: string;
    subtitle: string;
    sectionId: string;
    selected(sectionId: string): void;
}

const SectionCell: React.FunctionComponent<SectionCellProps> = (props) => {
    return (
        <TableRow
            style={{ cursor: 'pointer' }}
            onClick={() => {
                props.selected(props.sectionId);
            }}
        >
            <TableCell scope="row">
                <Typography variant="body1" align="left">
                    {props.title}
                </Typography>
                <Typography variant="subtitle2" align="left">
                    {props.subtitle}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Icon>
                        <KeyboardIconRight />
                    </Icon>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default SectionCell;
