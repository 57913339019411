import { Button, Card, CardActions, CardContent, CardMedia, LinearProgress, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import currencyFormatter from 'common/utilities/currency_formatter';
import BidCountdown from 'components/bidCountdown';
import { observer } from 'mobx-react-lite';
import { IBidStatusDto } from 'models/dtos/IBidStatusDto';
import CatalogSectionLotCellViewModel from 'pages/catalog_section/viewModels/catalogSectionLotCellViewModel';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from 'stores';
import { Image } from './image';

const styles = makeStyles((theme) =>
    createStyles({
        card: {
            maxWidth: 375,
        },
        cardImage: {
            height: 200,
            objectFit: 'contain',
            width: '100%',
        },
    })
);

export interface LotCardProps {
    model: CatalogSectionLotCellViewModel;
    catalogId: string;
    lotId: string;
    initialBidStatus: IBidStatusDto | undefined;
}

const LotCard: React.FunctionComponent<LotCardProps> = observer((props) => {
    const classes = styles();
    const { biddingStore, userStore } = React.useContext(StoreContext);
    const { t } = useTranslation();
    const navigate = useNavigate();

    var bidStatus = props.initialBidStatus;
    if (bidStatus === undefined) {
        bidStatus = biddingStore.bidStatus(props.catalogId, props.lotId);
    }

    const biddingDeadline = bidStatus && new Date(bidStatus.biddingDeadline);
    const currentDate = new Date();
    const canBid = bidStatus && bidStatus.canBid && biddingDeadline ? biddingDeadline > currentDate : false;
    const showBidding = bidStatus && bidStatus.canBid ? bidStatus && bidStatus.canBid : false;

    React.useEffect(() => {
        if (!props.model.archived) {
            biddingStore.subscribeForBidUpdates(props.catalogId, props.lotId);
        }

        return () => {
            biddingStore.unsubscribeForBidUpdates(props.catalogId, props.lotId);
        };
    });

    return (
        <Card className={classes.card} style={{ cursor: 'pointer' }}>
            {props.model.imageUrl && (
                <CardMedia style={{ backgroundColor: props.model.imageBackgroundColor }} onClick={() => navigate(props.model.detailsUrl)}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Image image={props.model.imageUrl} preferredHeight={250} imageWidth={props.model.imageWidth} imageHeight={props.model.imageHeight} />
                    </div>
                </CardMedia>
            )}
            <CardContent onClick={() => navigate(props.model.detailsUrl)}>
                <Typography variant="body2">
                    {props.model.title}
                    <br />
                    {props.model.text}

                    {props.model.note && (
                        <React.Fragment>
                            <br />
                            <br />
                            {props.model.note}
                        </React.Fragment>
                    )}
                </Typography>

                {props.model.archived && (
                    <Typography style={{ marginTop: '1em', fontWeight: 'bold' }} variant="body2">
                        {props.model.soldText}
                    </Typography>
                )}

                {props.model.valuation && (
                    <Typography style={{ marginTop: '1em' }} variant="body2">
                        {t('valuation')}: <b>{props.model.valuation}</b>
                    </Typography>
                )}

                {!props.model.archived && !bidStatus && <LinearProgress style={{ marginTop: '0.5em' }} variant="query" />}

                {showBidding && bidStatus && bidStatus.hasWinningBid && bidStatus.winningBid && (
                    <Typography style={{ marginTop: '1em', fontWeight: 'bold' }} variant="body2">
                        {t('bidding_you_are_highest_bidder', { amount: currencyFormatter(bidStatus.winningBid) })}
                    </Typography>
                )}

                {showBidding && bidStatus && !bidStatus.hasWinningBid && bidStatus.hasBids && bidStatus.usersMaxBid && (
                    <Typography style={{ marginTop: '1em', fontWeight: 'bold' }} variant="body2">
                        {t('bidding_you_have_been_overbid')}
                    </Typography>
                )}

                {showBidding && bidStatus && canBid && !bidStatus.hasWinningBid && bidStatus.nextMinimumBid && (
                    <Typography style={{ marginTop: '1em', fontWeight: 'bold' }} variant="body2">
                        {t('bidding_next_possible_bid', { amount: currencyFormatter(bidStatus.nextMinimumBid) })}
                    </Typography>
                )}

                {showBidding && props.model.onlineAuction && biddingDeadline && canBid && (
                    <Typography style={{ marginTop: '1em', fontWeight: 'bold' }} variant="body2">
                        {t('bidding_ends_at')} <BidCountdown biddingDeadline={biddingDeadline} />
                    </Typography>
                )}

                {showBidding && biddingDeadline && !canBid && (
                    <Typography style={{ marginTop: '1em', fontWeight: 'bold' }} variant="body2">
                        {t('lot_card_bidding_over')}
                    </Typography>
                )}
            </CardContent>
            <CardActions>
                <Button onClick={() => navigate(props.model.detailsUrl)} color="primary" size="small">
                    {t('lot_card_see_details')}
                </Button>

                {userStore.isAuthenticated && bidStatus && biddingDeadline && canBid && (
                    <Button
                        onClick={() => biddingStore.startMaxBid(props.catalogId, props.lotId, bidStatus!.nextMinimumBid, String(props.model.catalogNumber), props.model.text)}
                        color="primary"
                        size="small"
                    >
                        {t('bidding_place_bid')}
                    </Button>
                )}

                {!userStore.isAuthenticated && (
                    <Button onClick={() => navigate('/logon')} color="primary" size="small">
                        {t('generic.logon')}
                    </Button>
                )}
            </CardActions>
        </Card>
    );
});

export default LotCard;
