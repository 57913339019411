import { createContext } from 'react';
import { UserStore } from './UserStore';
import { ArchiveStore } from './ArchiveStore';
import { CatalogStore } from './CatalogStore';
import { AuctionStore } from './AuctionStore';
import { BiddingStore } from './BiddingStore';
import { ToastStore } from './ToastStore';
import { PurchaseStore } from './PurchaseStore';
import { MyBidsStore } from './MyBidsStore';

class Store {
    toastStore = new ToastStore(this);
    userStore = new UserStore(this);
    archiveStore = new ArchiveStore(this);
    catalogStore = new CatalogStore(this, this.toastStore);
    auctionStore = new AuctionStore(this, this.toastStore);
    biddingStore = new BiddingStore(this);
    purchaseStore = new PurchaseStore(this, this.toastStore);
    myBidsStore = new MyBidsStore(this, this.toastStore);
}

export type IStore = Store;

export const StoreContext = createContext(new Store());
