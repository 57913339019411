import { IBidLevelDto } from './IBidLevelDto';

export interface IBidStatusDto {
    catalogId: string;
    lotId: string;
    canBid: boolean;
    bidLevels: IBidLevelDto[];
    hasBids: boolean;
    hasWinningBid: boolean;
    nextMinimumBid: number;
    usersMaxBid?: number;
    winningBid?: number;
    biddingDeadline: string;
}

export function isEqual(first: IBidStatusDto, second: IBidStatusDto): boolean {
    return (
        first.catalogId === second.catalogId &&
        first.lotId === second.lotId &&
        first.canBid === second.canBid &&
        first.hasBids === second.hasBids &&
        first.hasWinningBid === second.hasWinningBid &&
        first.nextMinimumBid === second.nextMinimumBid &&
        first.usersMaxBid === second.usersMaxBid &&
        first.winningBid === second.winningBid &&
        first.biddingDeadline === second.biddingDeadline
    );
}
