import { Button, Container, Grid, Link, TextField, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from 'stores/index';
import { LogonResult } from 'stores/models/LogonResult';
import { validateEmail } from 'utilities/validateEmail';

export interface LogonProps {}

const useStyles = makeStyles((theme: Theme) => ({
    bottomButtons: {
        marginTop: theme.spacing(1),
    },
}));

const Logon: React.FunctionComponent<LogonProps> = observer(() => {
    const { userStore } = React.useContext(StoreContext);
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState('');
    const { t } = useTranslation();
    const navigate = useNavigate();

    const styles = useStyles();

    if (userStore.isAuthenticated) {
        navigate('/');
        return <React.Fragment />;
    }

    const handleLogon = async (event: React.SyntheticEvent) => {
        event.preventDefault();
        const result = await userStore.login(username, password, navigate);

        switch (result.status) {
            case LogonResult.success:
                navigate('/');
                break;
            case LogonResult.failure:
                setError(result.message);
                break;
            case LogonResult.newPassword:
                // TODO: this doesnt work. result.user is deallocated upon page switch by the AWS SDK.
                navigate('/logon/new_password', { state: { user: result.user } });
                break;
            case LogonResult.userDoesNotExist:
                setError(result.message);
                break;
            case LogonResult.wrongPassword:
                setError('Du har indtastet forkert bruger eller kodeord');
                break;
            case LogonResult.passwordResetRequired:
                navigate('/logon/forgot_password', { state: { username } });
                break;
            case LogonResult.requiresConfirmation:
                navigate('/create_user/confirm_code', { state: { email: username } });
                break;
            case LogonResult.acceptTerms:
                break;
        }
    };

    return (
        <div style={{ marginTop: '1em' }}>
            <Container maxWidth="xs">
                <Typography variant="h5">Log på</Typography>
                <form noValidate onSubmit={handleLogon}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={t('logon.email_address')}
                        name="email"
                        autoComplete="email"
                        onChange={(e: any) => {
                            setUsername(e.currentTarget.value);
                        }}
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t('logon.password')}
                        type="password"
                        id="password"
                        onChange={(e: any) => {
                            setPassword(e.currentTarget.value);
                        }}
                        autoComplete="current-password"
                    />
                    <Button type="submit" fullWidth variant="contained" color="primary" disabled={validateEmail(username) === false}>
                        {t('generic.logon')}
                    </Button>
                    <Grid container className={styles.bottomButtons}>
                        <Grid item xs>
                            <Link style={{ cursor: 'pointer' }} variant="body2" onClick={() => navigate('/logon/forgot_password', { state: { username } })}>
                                {t('logon.forgotten_password')}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link style={{ cursor: 'pointer' }} variant="body2" onClick={() => navigate('/logon/create_user')}>
                                {t('logon.create_account')}
                            </Link>
                        </Grid>
                    </Grid>
                    {error && (
                        <Typography variant="body2" color="error">
                            {error}
                        </Typography>
                    )}
                </form>
            </Container>
        </div>
    );
});

export default Logon;
