import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import currency_formatter from 'common/utilities/currency_formatter';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { StoreContext } from 'stores/index';
import ActivityIndicator from 'common/views/activityIndicator';
import { IBidLevelDto } from '../../models/dtos/IBidLevelDto';

export interface MaxBidToggleProps {
    initialBid: number;
}

const MaxBidToggle: React.FunctionComponent<MaxBidToggleProps> = (props) => {
    const [bid, setBid] = React.useState<number | undefined>();
    const { biddingStore } = React.useContext(StoreContext);
    const { catalogId, lotId } = biddingStore.currentBid!;
    const bidStatus = biddingStore.bidStatus(catalogId, lotId);

    if (!bidStatus) {
        return <div />;
    }

    if (!bid) {
        setBid(props.initialBid);
    }

    if (!bidStatus) {
        return <ActivityIndicator>Finder bud</ActivityIndicator>;
    }

    function findBidLevel(bidLevels: IBidLevelDto[], currentValue: number): IBidLevelDto {
        return bidLevels.find((x) => currentValue < x.toValue) || bidLevels.reduce((curr, next) => (curr.toValue > next.toValue ? curr : next));
    }

    const calculateNextBid = (add: boolean, limit = true): number => {
        if (add) {
            const bidLevel = findBidLevel(bidStatus.bidLevels, bid!);
            const nextValue = bidLevel.value + bid!;
            return nextValue;
        } else {
            const bidLevel = findBidLevel(bidStatus.bidLevels, bid!);
            const nextValue = bid! - bidLevel.value;
            return limit ? Math.max(nextValue, props.initialBid) : nextValue;
        }
    };

    const updateAmount = (add: boolean) => {
        const newAmount = calculateNextBid(add);
        setBid(newAmount);
    };

    biddingStore.currentBid!.bid = bid!;

    return (
        <React.Fragment>
            <IconButton
                key="minus"
                aria-label="mindre"
                disabled={calculateNextBid(false, false) < props.initialBid}
                color="inherit"
                onClick={() => updateAmount(false)}
                size="large">
                <RemoveIcon />
            </IconButton>
            <span style={{ userSelect: 'none', WebkitUserSelect: 'none', cursor: 'default' }}>{currency_formatter(bid!)}</span>
            <IconButton
                key="add"
                aria-label="mindre"
                color="inherit"
                onClick={() => updateAmount(true)}
                size="large">
                <AddIcon />
            </IconButton>
        </React.Fragment>
    );
};

export default MaxBidToggle;
